import { cloneDeep as clone } from 'lodash'

import i18n from '@i18n'

import { getFolderDisplayName } from '@utils/folder'

const root = {
  name: 'home',
  crumb: {
    i18n: 'router.routes.path.home.crumb',
    to: { name: 'home' },
    exact: true,
  },
}

/**
 * Module for breadcrumbs
 */
export default {
  namespaced: true,

  state: () => ({
    list: [],
  }),

  mutations: {
    pop(state) {
      state.list.pop()
    },
    replace(state, { text, params }) {
      const last = state.list[state.list.length - 1]
      if (last) {
        last.crumb.text = text || last.crumb.text
        last.crumb.to.params = Object.assign(last.crumb.to.params || {}, params)
      }
    },
    removeDuplicates(state, { id, route }) {
      const idx = state.list.findIndex(
        e => e.name === route.name && e.crumb.to.params.id === id
      )
      if (idx > -1) {
        state.list.splice(idx, 1)
      }
    },
    route(state, { to, routes }) {
      if (state.list.length === 0) {
        state.list.push(root)
        build(state.list, to, routes)
      } else if (hasCrumb(to)) {
        if (to.meta.crumb.parent === 'root' && !to.meta.crumb.stack) {
          state.list.length = 0
          state.list.push(root)
          push(state.list, to)
        } else {
          splice(state.list, to, routes)
          push(state.list, to)
        }
      }
    },
    addFolderCrumb(state, folder) {
      folderCrumb(state.list, folder)
    },
  },
  getters: {
    crumbs: state => () => {
      return state.list.map(e => {
        const c = clone(e.crumb)
        if (c.i18n && !c.text) {
          c.text = i18n.t(c.i18n)
        }
        delete c.i18n
        return c
      })
    },
  },
}

/**
 * builds the breadcrumb recursively
 * @param {*} list
 * @param {*} route
 * @param {*} routes
 */
function build(list, route, routes) {
  if (hasCrumb(route)) {
    const parent = findParent(route, routes)
    if (parent) {
      build(list, parent, routes)
    }
    push(list, route)
  }
}

/**
 * splices the breadcrumb recursively
 * @param {*} list
 * @param {*} route
 * @param {*} routes
 */
function splice(list, route, routes) {
  const idx = list.findIndex(
    e =>
      e.name === route.name &&
      (!route.params.isRef || e.crumb.to.params.id === route.params.id)
  )
  if (idx !== -1) {
    list.splice(idx)
  } else if (hasCrumb(route)) {
    const parent = findParent(route, routes)
    if (parent) {
      splice(list, parent, routes)
    }
  }
}

/**
 * searches a route name recursively
 * @param {*} routes
 * @param {*} name
 */
function find(routes, name) {
  return (
    routes.find(route => {
      return route.name === name
    }) || findr(routes, name)
  )
}

/**
 * searches a route name recursively in all children
 * @param {*} routes
 * @param {*} name
 */
function findr(routes, name) {
  return routes
    .filter(e => Array.isArray(e.children))
    .map(e => e.children)
    .find(e => find(e, name))
}

/**
 * finds the parent route of the given one
 * @param {*} route
 * @param {*} routes
 */
function findParent(route, routes) {
  const parent = route.meta.crumb.parent
  return find(routes, parent)
}

/**
 * checks if a route has a crumb definition
 * @param {*} route
 */
function hasCrumb(route) {
  return route.meta && route.meta.crumb
}

/**
 * converts and pushes the route meta crumb
 * to a breadcrumb and pushes it to the list.
 * @param {*} list
 * @param {*} route
 */
function push(list, route) {
  if (hasCrumb(route)) {
    list.push({
      name: route.name,
      crumb: {
        i18n: route.meta.crumb.i18n,
        text: route.meta.crumb.text,
        exact: true,
        to: {
          name: route.name,
          params: Object.assign(
            clone(route.params),
            route.meta.crumb.params || {}
          ),
          query: Object.assign(
            clone(route.query),
            route.meta.crumb.query || {}
          ),
        },
      },
    })
  }
}

/**
 * adds folders to the breadcrumb
 * @param {Object[]} list current breadcrumbs
 * @param {Object} folder currently viewed folder
 */
function folderCrumb(list, folder) {
  if (!folder.id) {
    return
  }

  const rootName = i18n.t('router.routes.path.folder.title')
  const crumbPath = folder.parents.map(item => {
    return {
      name: folder.name,
      crumb: {
        exact: true,
        text: item.depth === 0 ? rootName : getFolderDisplayName(item),
        to: { name: 'folder', params: { id: item.id } },
        disabled: folder.id === item.id,
      },
    }
  })
  const isRoot = (folder.parents || []).length === 0
  crumbPath.push({
    name: folder.name,
    crumb: {
      exact: true,
      text: isRoot ? rootName : getFolderDisplayName(folder),
      to: { name: 'folder', params: { id: folder.id } },
      disabled: false,
    },
  })
  list.push(...crumbPath)
}
