import Vue from 'vue'
import VueRouter from 'vue-router'

import api from '@api'

import store from '@state/store'
import { productUrls, productUrlExceptions } from '@utils/env'

import routes from './routes'

Vue.use(VueRouter)

const LOGIN_URL_REDIRECTS = Object.freeze([
  {
    routeName: 'archive-login',
    urls: productUrls.archivesaas,
    exceptions: productUrlExceptions.archivesaas
  },
])

/**
 * router
 * @see https://router.vuejs.org
 */
const router = new VueRouter({
  base: process.env.VUE_APP_BASE_PATH,
  mode: 'history',
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

/**
 * redirect
 * login
 */
const redirectToLogin = (to, from, next) => {
  return next({
    name: 'login',
    query: {
      redirectFrom: to.fullPath,
    },
  })
}

const subdomainLoginRedirect = (to, from, next) => {
  const href = window.location.href
  LOGIN_URL_REDIRECTS.forEach(redirectInfo => {
    const isDifferentRoute = to.name !== redirectInfo.routeName
    const isMatchingUrl = redirectInfo.urls.some(part => href.includes(part))

    if (isDifferentRoute && isMatchingUrl) {
      // Check if the current URL matches any exception
      if (redirectInfo.exceptions?.some(exception => href.includes(exception))) {
        return next()
      }
      let query =
        to.name === 'login'
          ? to.query
          : {
              redirectFrom: to.fullPath,
            }
      return next({
        name: redirectInfo.routeName,
        query,
      })
    }
  })
  return next()
}

/**
 * guard
 * before routing
 */
router.beforeEach(async (to, from, next) => {
  if (typeof to.meta.title !== 'undefined') {
    let titleSuffix = ' - EASY DM'
    if (!!to.meta.clean) {
      titleSuffix = ''
    }
    document.title = router.app.$t(to.meta.title) + titleSuffix
  }
  if (typeof to.meta.authRequired === 'undefined') {
    to.meta.authRequired = true
  }
  if (to.name.includes('login') && !to.name.includes('help')) {
    return subdomainLoginRedirect(to, from, next)
  }
  if (!to.meta.authRequired || api.auth.authenticated) {
    if (!store.state.app.context.authed && api.auth.authenticated) {
      return load(to, from, next)
    }
    return next()
  }
  if (api.auth.ready && store.state.app.login.customer) {
    return login(to, from, next)
  }
  return redirectToLogin(to, from, next)
})

/**
 * guard
 * before resolving
 */
router.beforeResolve(async (to, from, next) => {
  if (to.meta.admin) {
    if (store.getters['app/context/isAdmin']()) {
      if (!store.state.app.nav.adminMode) {
        store.commit('app/nav/adminMode')
      }
    } else {
      return next({ name: 'error-403' })
    }
  }
  if (store.state.app.detail.unsaved) {
    if (
      from.name === 'entity' &&
      store.state.app.detail.editing &&
      !store.state.app.detail.creating
    ) {
      store.commit('app/detail/emit', { event: 'saveEditing' })
      return next()
    }
    if (!to.meta?.system) {
      store
        .dispatch('app/dialog/decide', 'unsaved')
        .then(() => {
          store.commit('app/detail/emit', { event: 'save' })
          if (store.state.app.detail.valid) {
            return next()
          }
        })
        .catch(() => {
          store.commit('app/detail/reset')
          return next()
        })
    } else {
      return next()
    }
  } else {
    return next()
  }
})

/**
 * guard
 * after routing
 */
router.afterEach((to, from) => {
  router.$last = from
  router.app.$store.commit('app/crumbs/route', {
    to: to,
    routes: routes,
  })
})

export default router

/**
 * performs the login
 */
async function login(to, from, next) {
  let path = from?.query?.redirectFrom || to.fullPath
  path = process.env.VUE_APP_BASE_PATH + path
  path = path.replace(/\/+/g, '/')

  await api.auth.login({
    redirectUri: window.location.origin + path,
    loginHint: store.state.app.login.hint,
    idpHint: store.state.app.login.idp,
  })

  return await load(to, from, next)
}

/**
 * loads app context
 */
async function load(to, from, next) {
  try {
    await store.dispatch('app/context/load', { checkProductActivation: true })
    return next()
  } catch (err) {
    console.warn('could not load app context. redirect to login')
    return redirectToLogin(to, from, next)
  }
}
