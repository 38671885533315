import i18n from '@i18n'

import model from './model'
import sapi from './service'

export default {
  async get() {
    try {
      const { data } = await sapi.webadapter.get('/user/config')
      return model.userConfig(data)
    } catch (err) {
      throw err
    }
  },
  async save(data, silent) {
    await sapi.webadapter.put('/user/config', data)
    if (!silent) {
      sapi.success(
        i18n.t('dm.system.alert.updated', {
          name: i18n.t('dm.system.table.setting'),
        })
      )
    }
  },
  async update(data) {
    await sapi.webadapter.patch('/user/config', data)
  },
}
