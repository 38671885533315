import sapi from './service'

const endpoint = '/admin/customservices'

export default {
  async list() {
    const { data } = await sapi.webadapter.get(endpoint)
    return data
  },
}
