import { cloneDeep as clone } from 'lodash'
import moment from 'moment'

import api from '@api'
import i18n from '@i18n'

import getFileExtension from '@gm/utils/file-extension'
import Scanners from '@gm/utils/scanners'
import loadWebTwainResources from '@gm/utils/script-loader'

const SCANNABLE_TRAIT_NAME = 'Scannable'

let scanners = null

export default {
  namespaced: true,

  state: () => ({
    webTwainResourcesLoaded: false,
    cannotConnect: false,
    initialized: false,
    isBusy: false,
    isScanning: false,
    isMultifunctionScanner: false,
    useADF: false,
    isFinishing: false,
    currentDevice: { name: '', index: -1, config: {} },
    devices: [],
    images: [],
    documentTypes: [],
    document: {},
  }),

  getters: {
    arePagesChecked: (_state, getters) => {
      return getters.checkedPagesCount > 0
    },
    isInitialized: state => {
      return state.initialized
    },
    cannotConnect: state => {
      return state.cannotConnect
    },
    currentDeviceName: state => {
      return state.currentDevice.name
    },
    devices: state => {
      return state.devices
    },
    deviceNames: state => {
      return state.devices.map(d => d.name)
    },
    webTwainResourcesLoaded: state => {
      return state.webTwainResourcesLoaded
    },
    images: state => {
      return state.images
    },
    isScanning: state => {
      return state.isScanning
    },
    useADF: state => {
      return state.useADF
    },
    isMultifunctionScanner: state => {
      const config = state.currentDevice.config
      return config.flatbed && config.feeder
    },
    isBusy: state => {
      return state.isBusy
    },
    isFinishing: state => {
      return state.isFinishing
    },
    document: state => {
      return state.document
    },
    documentTypes: state => {
      const getLabel = function (type) {
        const label = type.label[i18n.locale]
        if (label) {
          return label
        }
        return type.name
      }

      return state.documentTypes.map(t => {
        return {
          id: t.id,
          name: t.name,
          label: getLabel(t),
        }
      })
    },
    checkedPagesCount: state => {
      return state.images.filter(image => {
        return image.checked
      }).length
    },
    selectedPage: state => {
      return state.images.findIndex(image => image.isSelected())
    },
  },

  mutations: {
    initialized: state => {
      state.initialized = true
    },
    devices: (state, names) => {
      state.devices = names.map((name, index) => {
        return { name, index, config: {} }
      })
    },
    currentDevice: (state, deviceName) => {
      state.currentDevice = state.devices.find(d => {
        return d.name === deviceName
      })
    },
    updateCurrentDevice: (state, config) => {
      state.currentDevice.config = config
    },
    document: (state, document) => {
      state.document = document
    },
    documentTypes: (state, types) => {
      state.documentTypes = types
    },
    webTwainResourcesLoaded: state => {
      state.webTwainResourcesLoaded = true
    },
    images: (state, images) => {
      state.images = images
    },
    addImages: (state, images) => {
      state.images = state.images.concat(images)
    },
    moveImage: (state, { sourceIndex, targetIndex, after }) => {
      if (targetIndex === sourceIndex) {
        // moved to same index -> noop
        return
      }
      if (sourceIndex >= state.images.length) {
        // invalid index
        return
      }

      const images = state.images
      if (after) {
        // Per default the image at the target index will shift to the right, so
        // the source image will be inserted before the target index.
        // Here we increment the target index to place it after the original
        // target index.
        targetIndex += 1
      }

      const placeholder = {}
      // remove the object from its initial position and plant the placeholder
      // object in its place to keep the array length constant
      const image = images.splice(sourceIndex, 1, placeholder)[0]
      // place the object in the desired position
      images.splice(targetIndex, 0, image)
      // replace temporary object
      images.splice(images.indexOf(placeholder), 1)
    },
    cannotConnect: (state, cannotConnect) => {
      state.cannotConnect = cannotConnect
    },
    isBusy: (state, busy) => {
      state.isBusy = busy
    },
    isScanning: (state, scanning) => {
      state.isScanning = scanning
    },
    isFinishing: (state, finishing) => {
      state.isFinishing = finishing
    },
    removeAllScannedImages: state => {
      state.images.forEach(image => image.destroy())
      state.images = []
    },
    removeCheckedImages: state => {
      state.images = state.images.filter(image => {
        const removeImage = image.checked
        if (removeImage) {
          image.destroy()
        }
        return !removeImage
      })
    },
    selectPage: (state, index) => {
      for (let i = 0; i < state.images.length; i++) {
        state.images[i].deselect()
      }
      state.images[index].select()
    },
    useADF: (state, useADF) => {
      state.useADF = useADF
    },
    togglePageCheckbox: (state, index) => {
      state.images[index].toggleChecked()
    },
  },

  actions: {
    async loadImages({ commit, dispatch, rootState, state }, images) {
      const fetchScannableTypes = dispatch('fetchScannableTypes')

      // duplicated code fragment (16 lines long)
      if (state.images.length === 0) {
        commit('images', images)
        commit('selectPage', 0)
      } else {
        commit('addImages', images)
      }

      await fetchScannableTypes
      const now = moment()
      const doc = {
        type: state.documentTypes[0].id,
        'system.creator': rootState.app.context.user.name,
        'system.created': now.toISOString(),
        'system.origin': 'webscan',
      }
      commit('document', doc)
    },
    async loadWebTwainResources({ commit }) {
      await loadWebTwainResources()
      commit('webTwainResourcesLoaded')
    },
    async initialize({ commit, dispatch, rootGetters, state }) {
      if (!scanners) {
        scanners = await Scanners.build()
        commit('initialized')
      }

      await this.dispatch('gm/settings/user/load')
      const config = rootGetters['gm/settings/user/userSettings']

      scanners.list().then(devices => {
        if (devices.length === 0) {
          return
        }
        commit('devices', devices)

        const configuredDevice = state.devices.find(
          device => device.name === config.scanner
        )
        const hasConfiguredDevice = typeof configuredDevice !== 'undefined'

        // Use scanner found in config if it is within the list of scanners
        // provided by webtwain, else use first provided by webtwain
        if (hasConfiguredDevice) {
          dispatch('selectDevice', config.scanner)
        } else {
          dispatch('selectDevice', state.devices[0].name)
        }
      })

      if (config.profile) {
        dispatch('gm/profile/selectProfile', config.profile, { root: true })
      }
    },
    async selectDevice({ commit, state }, deviceName) {
      commit('isBusy', true)
      commit('cannotConnect', false)
      commit('currentDevice', deviceName)
      try {
        if (Object.keys(state.currentDevice.config).length === 0) {
          // config is unknown, fetch config from scanner
          const config = await scanners.getConfig(state.currentDevice.index)
          console.info('found config for', state.currentDevice.name, config)
          await commit('updateCurrentDevice', config)
        }
      } catch (error) {
        console.warn('failed to get config', error)
        commit('cannotConnect', true)
      } finally {
        commit('isBusy', false)
      }
    },
    async scan({ commit, state, dispatch, rootState, rootGetters }) {
      commit('isScanning', true)

      try {
        const fetchScannableTypes = dispatch('fetchScannableTypes')
        const profile = rootGetters['gm/profile/currentProfile']
        const images = await scanners.scan(
          profile,
          state.useADF,
          state.currentDevice.index
        )

        if (state.images.length === 0) {
          commit('images', images)
          commit('selectPage', 0)
        } else {
          commit('addImages', images)
        }

        await fetchScannableTypes
        const now = moment()
        const doc = {
          type: state.documentTypes[0].id,
          'system.creator': rootState.app.context.user.name,
          'system.created': now.toISOString(),
          'system.origin': 'webscan',
        }
        commit('document', doc)
      } finally {
        commit('isScanning', false)
      }
    },
    async fetchScannableTypes(context) {
      // fetch all available types
      const types = await api.entity.types('edit')

      const scannableTypes = types.filter(
        t => getScannableTrait(t.traits) !== undefined
      )

      if (scannableTypes.length === 0) {
        throw Error('No document with trait Scannable found')
      }
      context.commit('documentTypes', scannableTypes)
    },
    async finishScan({ commit, state }) {
      commit('isFinishing', true)

      const trait = state.documentTypes
        .filter(t => t.id === state.document.type)
        .map(t => getScannableTrait(t.traits))[0]

      let blobReferences = []
      for (let i = 0; i < state.images.length; i++) {
        const type = state.images[i].getMIMEType()
        const name = 'page-' + (i + 1) + getFileExtension(type)
        const file = new File([state.images[i].getBlob()], name, {
          type: type,
        })

        // TODO NDMS-8548 add file size check from NDMS-1620
        // - add filesize mixin
        // - check with "this.isFileSizeReached(file)"
        // if limit is reached:
        // - display warning with "await this.showFileSizeWarning()"  and cancel the upload

        const { blobID } = await api.blob.upload(file)

        blobReferences.push({
          id: blobID,
          name: name,
        })
      }

      const sourceAttribute = trait.id + '.images'

      const copy = clone(state.document)
      copy[sourceAttribute] = {
        '@type': 'file',
        value: blobReferences.map(b => {
          return { id: b.id, name: b.name }
        }),
      }

      commit('document', copy)

      const { entityId } = await api.entity.save(copy)

      // At the moment there is no way to get all orchestration tasks for the entity,
      // so we are polling the entity until attribute 'binary.file' is filled
      // That will be removed when the detail view is able to show the
      // orchestration progress
      const targetAttribute = 'binary.file'
      return new Promise(resolve => {
        const timeout = 1000
        const maxWaitTime = 60 * timeout // max 60 seconds
        let counter = 0
        let timerId = setInterval(async () => {
          counter += timeout

          const entity = await api.entity.get(entityId, 'view')

          let hasPDF = false
          if (entity.values && entity.values[targetAttribute]) {
            const files = entity.values[targetAttribute].value
            if (files.length > 0) {
              hasPDF = files[0].name.indexOf('.pdf') !== -1
            }
          }

          if (counter > maxWaitTime) {
            hasPDF = true // exit if it takes to long
          }

          if (hasPDF) {
            clearInterval(timerId)
            commit('isFinishing', false)
            resolve(entityId)
          }
        }, timeout)
      })
    },
  },
}

/**
 * @typedef {Object} Trait
 * @property {string} id trait id
 * @property {string} name name of the trait
 * @property {string} group the trait group
 * @property {object} label the translations e.g. {de: 'Label', en: 'Label'}
 */

/**
 * Returns the trait with the name Scannable if it exists in the given trait list.
 *
 * @param {Array.<Trait>} traits list of traits
 * @returns {undefined|Trait} trait object if trait with name Scannable is found or undefined otherwise
 */
function getScannableTrait(traits) {
  if (!traits) {
    return undefined
  }
  const scannableTraits = traits.filter(trait => {
    return trait.name === SCANNABLE_TRAIT_NAME
  })
  return scannableTraits.length === 0 ? undefined : scannableTraits[0]
}
