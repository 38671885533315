import i18n from '@i18n'

import sapi from './service'

const endpoint = '/public/blobs'

export default {
  async src(file) {
    const data = await sapi.architect.blob.src(endpoint, file)
    return data
  },

  async text(file) {
    const data = await sapi.architect.blob.text(endpoint, file)
    return data
  },

  async download(file) {
    const data = await sapi.architect.blob.download(endpoint, file)
    return data
  },

  async upload(file) {
    const data = await sapi.architect.blob.upload(
      endpoint,
      file,
      'dm/upload/progressPublic'
    )
    sapi.success(
      i18n.t('dm.blob.alert.uploaded', {
        name: file.name,
      })
    )
    return data
  },
}
