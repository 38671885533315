import Vue from 'vue'
import Vuex from 'vuex'

import gm from '@gm/state'

import app from './modules/app/'
import dm from './modules/dm/'

Vue.use(Vuex)

/**
 * vuex state
 * @see https://vuex.vuejs.org
 */
const store = new Vuex.Store({
  modules: {
    app,
    dm,
    gm,
  },
  strict: process.env.VUE_APP_MODE !== 'production',
})

initModules()

export default store

/**
 * initialize state modules
 */
function initModules() {
  store.dispatch('app/login/init')
  store.dispatch('app/theme/init')
  store.dispatch('app/style/init')
  store.dispatch('app/nav/init')
  store.dispatch('app/telemetry/init')
}
