export default (model = {}) => {
  return {
    ...{
      roles: {
        tenantadmin: false,
        tenantuser: false,
      },
      attributes: {},
      systemidentities: [],
    },
    ...model,
  }
}
