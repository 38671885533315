import { filesize } from 'filesize'

import i18n from '@i18n'

/**
 * @param files {FileList | File[]}
 * @param limit {number} file size limit in MB
 * @returns {boolean} true, if any file size exceeds specified limit
 */
export function isAnyFileSizeLimitReached(files, limit) {
  return Array.from(files).some(f => isFileSizeLimitReached(f, limit))
}

/**
 * @param file {File}
 * @param limit {number} file size limit in MB
 * @returns {boolean} true, if file size exceeds specified limit
 */
export function isFileSizeLimitReached(file, limit) {
  return !!limit && file.size >= limit * 1000000
}

/**
 * Formats the size of a file in a human-readable string
 * @param sizeInBytes size in bytes
 * @return {string} human-readable file size string
 */
export function formatFileSize(sizeInBytes) {
  return filesize(sizeInBytes, {
    separator: i18n.locale === 'de' ? ',' : '.',
    round: 1,
  })
}

export function saveFileData(data, fileName) {
  const href = URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
