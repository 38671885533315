/* eslint-disable */
import vue from 'vue'

/**
 * Import core context
 */
import store from '@state/store'
import router from '@router'
import i18nInstance from '@i18n'
import i18n from './i18n'
import routes from './router/routes'
import sapi from '@api/service'
import api from '@api'
import mailfetcherApi from './api'

/**
 * Define feature constants
 */
const isDev = process.env.NODE_ENV === 'development' ? true : false
const VERSION = 'v0.0.1'
const FEATURE = 'mailfetcher'
const NAME = 'Mail Fetcher'
const ROUTER_NAME = 'mailfetcher'

/**
 * Define feature info with details of registered components
 */
const INFO = {
  name: NAME,
  feature: FEATURE,
  version: VERSION,
  routes: initRouter(),
  i18n: initI18n(),
  nav: initNav(),
  api: initApi(),
}

export default INFO

/**
 * Register and initialize api wrapper
 */
function initApi() {
  const servicedef = {
    url: 'mailfetcher/api/v1',
    name: 'mailfetcher',
    apikey: '83ac0f8d558704d9a444778304085797',
    system: 'easy1',
  }
  sapi.register(servicedef)
  api.mailfetcher = mailfetcherApi
  return sapi.mailfetcher !== undefined && api.mailfetcher !== undefined
}

/**
 * Register and initialize router context
 */
function initRouter() {
  routes.forEach(route => {
    router.addRoute('app', route)
  })

  const rr = router.getRoutes()
  return rr.some(route => route.name === 'mailfetcher-settings-email')
}

/**
 *  Register and initialize localization context
 */
function initI18n() {
  Object.keys(i18n).forEach(key => {
    i18nInstance.add(key, { [FEATURE]: i18n[key] })
  })

  // no status check
  return true
}

/**
 *  Register and initialize navigation context
 */
function initNav() {
  store.commit('app/features/addNavEntry', {
    id: 'mailfetcher',
    icon: 'mdi-email',
    title: 'mailfetcher.nav.group.emailIntegration.title',
    matcher: 'mailfetcher/[a-z]+',
    shouldBeShown: featureFlags =>
      featureFlags.includes('dm-show-admin-menu-entry-mailfetcher'),
    admin: true,
    items: [
      {
        id: 'mailfetcher-accounts',
        icon: 'mdi-email-edit',
        title: 'mailfetcher.nav.item.accounts.title',
        route: { name: 'settings-mailfetcher-email' },
      },
    ],
  })

  // no status check
  return true
}
