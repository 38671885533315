import ScannedImage from './scanned-image'

const WEBTWAIN_ID = 'WebscanWebTwain'

function dynamsoft() {
  return window.Dynamsoft
}

export default class Scanners {
  constructor(dwt) {
    if (typeof dwt === 'undefined') {
      throw new Error('Parameter dwt is undefined!')
    }
    // disable UI
    dwt.IfShowUI = false

    this.dwt = dwt
  }

  /**
   * Builder for class Scanners.
   * @returns {Promise<Scanners>}
   */
  static async build() {
    // disable installation dialog
    dynamsoft().OnWebTwainNotFoundOnLinuxCallback = function () {}
    dynamsoft().OnWebTwainNotFoundOnMacCallback = function () {}
    dynamsoft().OnWebTwainNotFoundOnWindowsCallback = function () {}

    // disable loading indicator
    dynamsoft().Lib.showMask = function () {}
    dynamsoft().Lib.hideMask = function () {}

    return new Promise((resolve, reject) => {
      dynamsoft().DWT.CreateDWTObjectEx(
        {
          WebTwainId: WEBTWAIN_ID,
        },
        dwt => {
          resolve(new Scanners(dwt))
        },
        error => {
          reject(new WebTwainError(error))
        }
      )
    })
  }

  /**
   * Get config for given index of scanner.
   * @param {number} index - index of scanner
   * @returns {Promise<{ type: number, flatbed: boolean, feeder: boolean }>} scanner's config
   */
  async getConfig(index) {
    return withConnection(this.dwt, index, async () => {
      // List of DeviceTypes:
      // https://www.dynamsoft.com/web-twain/docs/info/api/WebTwain_Acquire.html?ver=latest#getdevicetype
      const deviceType = this.dwt.GetDeviceType()
      if (deviceType === 0) {
        throw new ConfigInitError('Failed to get device type')
      }
      return {
        type: deviceType,
        flatbed: [2, 3, 5].includes(deviceType),
        feeder: [3, 4, 5, 6].includes(deviceType),
      }
    })
  }

  /**
   * List all active TWAIN scanners.
   * @returns {Promise<string[]>} list of scanner names
   */
  list() {
    return this.dwt.GetSourceNamesAsync()
  }

  /**
   * @param profile the profile to use. See vuex module 'gm/profile' for more information
   * @param useADF enable feeder if set to true
   * @param index the index of the scanner to use
   * @returns {Promise<>}
   */
  async scan(profile, useADF, index) {
    return withConnection(this.dwt, index, async () => {
      this.dwt.IfShowIndicator = false
      this.dwt.IfAutoBright = true
      this.dwt.IfAutomaticDeskew = true
      this.dwt.IfAutomaticBorderDetection = true

      return new Promise((resolve, reject) => {
        this.dwt.AcquireImage(
          {
            IfFeederEnabled: useADF,
            IfShowUI: false,
            PixelType: profile.colorMode,
            Resolution: profile.dpi,
          },
          () => {
            const startIndex = 0
            const imageCount = this.dwt.HowManyImagesInBuffer
            const scannedImages = []

            for (let i = startIndex; i < imageCount; i++) {
              const url = this.dwt.GetImageURL(i)
              const width = this.dwt.GetImageWidth(i)
              const height = this.dwt.GetImageHeight(i)
              scannedImages.push(ScannedImage.createImage(url, width, height))
            }
            Promise.all(scannedImages).then(images => {
              this.dwt.RemoveAllImages()
              resolve(images)
            })
          },
          (o, errorCode, errorString) => {
            this.dwt.RemoveAllImages()
            reject(new Error(errorCode + ': ' + errorString))
          }
        )
      })
    })
  }
}

/**
 * Creates a connection to the scanner on the given index befor calling the callback.
 * Also disconnects after the callback is finished.
 *
 * @param dwt the webtwain instance
 * @param index the index of the scanner to use
 * @param callback the function to execute
 * @returns {Promise<*>} result of the callback
 */
async function withConnection(dwt, index, callback) {
  try {
    const isConnected = await connect(dwt, index)
    if (!isConnected) {
      throw new ConnectionError(
        'Failed to connected to scanner at index ' + index
      )
    }

    return await callback()
  } finally {
    disconnect(dwt)
  }
}

/**
 * Connects to a twain compatible scanner on the given index.
 *
 * @param dwt the webtwain instance
 * @param index the index of the scanner to use
 * @returns {Promise<boolean>} true if connection is established, false otherwise
 */
async function connect(dwt, index) {
  if (!dwt.SetOpenSourceTimeout(15000)) {
    console.warn('Failed to set SetOpenSourceTimeout')
    return false
  }
  const isSelected = await dwt.SelectSourceByIndexAsync(index)
  if (!isSelected) {
    console.warn('Failed to select source by index ' + index)
    return false
  }
  try {
    return await dwt.OpenSourceAsync()
  } catch (err) {
    console.warn('Failed to open source with index ' + index)
    return false
  }
}

/**
 * Disconnects from the current connected scanner and release all used
 * resources for the connection.
 *
 * @param dwt the webtwain instance
 */
function disconnect(dwt) {
  const isClosed = dwt.CloseSource()
  if (!isClosed) {
    console.warn('Failed to close source')
  }
  const hasClosedWorkingProcess = dwt.CloseWorkingProcess()
  if (!hasClosedWorkingProcess) {
    console.warn('Failed to close working process')
  }
}

export class ConnectionError extends Error {
  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConnectionError)
    }
    this.name = 'ConnectionError'
  }
}

export class ConfigInitError extends Error {
  constructor(...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ConfigInitError)
    }
    this.name = 'ConfigInitError'
  }
}

export class WebTwainError extends Error {
  constructor(error = {}, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, WebTwainError)
    }

    this.name = 'WebTwainError'
    this.error = error
  }
}
