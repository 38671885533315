import { setLabel } from './utils'

export default (model = {}) => {
  return setLabel(
    {
      ...{
        name: '',
        label: {},
        slots: {
          main: {
            layouts: '',
            components: [],
          },
          actions: {
            layout: '',
            components: [],
          },
        },
      },
      ...model,
    },
    'label'
  )
}
