import { setLabel } from './utils'

export default (model = {}) => {
  return setLabel(
    {
      ...{
        components: [],
        pages: [],
        pagetype: '',
        title: {},
      },
      ...model,
    },
    'title'
  )
}
