const mimeTypes = {
  'image/png': 'png',
  'image/jpeg': 'jpeg',
  'image/jpg': 'jpg',
  'image/tiff': 'tiff',
}

export default function getFileExtension(mimeType) {
  const type = mimeTypes[mimeType]
  if (typeof type === 'undefined') {
    return ''
  }
  return '.' + type
}
