import i18n from '@i18n'

import model from './model'
import sapi from './service'

const endpoint = 'config/systems'

export default {
  async get(id) {
    const { data } = await sapi.hybridconnector.get(`${endpoint}/${id}`)
    return model.system(data)
  },

  async list(params = {}) {
    let { data } = await sapi.hybridconnector.get(endpoint, { params })
    data = data || []
    return data.map(e => model.system(e))
  },

  async create(item) {
    try {
      const { data } = await sapi.hybridconnector.post(endpoint, item, {
        omitError: true,
      })
      sapi.success(
        i18n.t('dm.system.alert.created', {
          name: item.name,
        })
      )
      return model.system(data)
    } catch (err) {
      if (!sapi.isStatus(err, 409)) {
        sapi.error(err)
      }
      throw err
    }
  },

  async update(item) {
    try {
      await sapi.hybridconnector.put(`${endpoint}/${item.id}`, item, {
        omitError: true,
      })
      sapi.success(
        i18n.t('dm.system.alert.updated', {
          name: item.name,
        })
      )
      return model.system(item)
    } catch (err) {
      if (!sapi.isStatus(err, 409)) {
        sapi.error(err)
      }
      throw err
    }
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async delete(item) {
    await sapi.hybridconnector.delete(`${endpoint}/${item.id}`)
    sapi.success(
      i18n.t('dm.system.alert.deleted', {
        name: item.name,
      })
    )
  },

  async refresh(id) {
    const { data } = await sapi.hybridconnector.get(`${endpoint}/${id}`, {
      params: { refreshtoken: true },
    })
    return model.system(data)
  },

  async types() {
    const { data } = await sapi.hybridconnector.get('templates/systems')
    return data || []
  },

  async template(type) {
    const { data } = await sapi.hybridconnector.get(`templates/systems/${type}`)
    return data || {}
  },
}
