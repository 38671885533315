import api from '@api'

import li from '@utils/list'

export default {
  namespaced: true,

  state: () => ({
    processing: false,
    data: {},
  }),

  mutations: {
    processing(state, bool) {
      state.processing = bool
    },
    set(state, data) {
      state.data = data
    },
    destroy(state, item) {
      if (state.data?.entities) {
        li.destroy(state.data.entities, item)
      }
      if (state.data?.processes) {
        delete state.data.processes[item.id]
      }
    },
  },

  actions: {
    async load({ commit, rootGetters }) {
      try {
        commit('processing', true)
        const isAdmin = rootGetters['app/context/isAdmin']()
        const data = isAdmin
          ? await api.process.listForAdmin()
          : await api.process.list()
        commit('set', data)
      } catch (err) {
        commit('set', {})
        throw err
      } finally {
        commit('processing', false)
      }
    },
  },

  getters: {
    entities: state => {
      return state.data.entities ? state.data.entities : []
    },
    processes: state => {
      return state.data.processes ? state.data.processes : {}
    },
    types: state => {
      return state.data.types ? state.data.types : {}
    },
  },
}
