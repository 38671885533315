/**
 * Module for app rendering
 */
export default {
  namespaced: true,

  state: () => ({
    loading: false,
    overlay: false,
    renderKey: 0,
    height: 0,
    width: 0,
  }),

  mutations: {
    loading(state, bool) {
      state.loading = bool
    },
    overlay(state, bool) {
      state.overlay = bool
    },
    resize(state) {
      state.height = window.innerHeight
      state.width = window.innerWidth
    },
    rerender(state) {
      state.renderKey++
    },
  },

  getters: {
    height:
      (state, _getters) =>
      (offset, excludeHeader = false, px = true) => {
        if (excludeHeader) {
          const height = state.height - offset
          return px ? `${height}px` : `${height}`
        }

        const height = state.height - offset
        return px ? `${height}px` : `${height}`
      },
    halfHeight:
      (_state, getters) =>
      (offset, spacing = 0, excludeHeader = false, px = true) => {
        const halfHeight =
          (getters.height(offset, excludeHeader, false) - spacing) / 2
        return px ? `${halfHeight}px` : `${halfHeight}`
      },
  },
}
