import { getEnvVar } from '@utils/env'

const SLASH = '/'
const WEBTWAIN_SCRIPT = 'webtwain-script'
const defaultConfig = {
  jsFiles: [
    'dynamsoft.webtwain.initiate.js',
    'dynamsoft.webtwain.config.js',
    'config.js',
  ],
}

/**
 * Create a complete resource URL for a file.
 * @param {string} file - file name
 * @returns {string} resource URL for given file
 */
export function createResourceUrl(file) {
  let url = getEnvVar('VUE_APP_WEBSCAN_RESOURCE_URL')
  if (url.slice(-1) !== SLASH) {
    url += SLASH
  }
  return url + file
}

/**
 * Loads a given JavaScript file.
 * @param {string} url - URL of JavaScript file
 * @param {string} className - class name given to script tag in HTML body
 * @returns {Promise<>}
 */
export function loadJavaScriptFile(url, className = WEBTWAIN_SCRIPT) {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.setAttribute('src', url)
    script.setAttribute('class', className)
    script.addEventListener('load', () => {
      resolve()
    })
    document.body.append(script)
  })
}

/**
 * Checks if JavaScript files are already added to HTML body.
 * @param {string} className - class name of tags in HTML body
 * @returns {boolean} true if script tags with given class names where found
 */
export function scriptsLoaded(className = WEBTWAIN_SCRIPT) {
  return document.getElementsByClassName(className).length === 3
}

export default async function loadWebTwainResources(config = defaultConfig) {
  if (scriptsLoaded()) {
    return
  }

  const scripts = config.jsFiles.map(file =>
    loadJavaScriptFile(createResourceUrl(file))
  )
  return Promise.all(scripts)
}
