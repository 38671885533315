import install from '@gm/state/install'
import profile from '@gm/state/profile'
import settings from '@gm/state/settings'
import webscan from '@gm/state/webscan'

/**
 * Modules for Gemini
 */
export default {
  namespaced: true,

  modules: {
    install,
    webscan,
    settings,
    profile,
  },
}
