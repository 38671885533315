import * as archives from './archives'
import * as plans from './plans'

// In other parts, this file typically contains a webpack call to
// find all .js files and use them. This is bad for testability,
// so we do this manually here.
export default {
  namespaced: true,
  modules: {
    archives: { namespaced: true, ...archives },
    plans: { namespaced: true, ...plans },
  },
}
