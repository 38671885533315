import sapi from '@api/service'
import gm from '@gm/router/routes'

import load from './load'

const routes = [
  {
    path: '*',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/home',
    name: 'home',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/login',
    name: 'login',
    component: () => load(import('@pages/LoginPage')),
    meta: {
      title: 'router.routes.path.login.title',
      authRequired: false,
    },
  },
  {
    path: '/login-help',
    name: 'login-help',
    component: () => load(import('@pages/LoginHelpPage.vue')),
    meta: {
      title: 'router.routes.path.loginHelp.title',
      authRequired: false,
    },
  },
  {
    path: '/archivelogin',
    name: 'archive-login',
    component: () => load(import('@/features/archivesaas/pages/LoginPage')),
    meta: {
      title: 'archivesaas.login.title',
      clean: true,
      authRequired: false,
    },
  },
  {
    path: '/archivelogin/:tenant',
    name: 'archivelogin-tenant',
    props: true,
    component: () => load(import('@/features/archivesaas/pages/LoginPage')),
    meta: {
      title: 'archivesaas.login.title',
      clean: true,
      authRequired: false,
    },
  },
  {
    path: '/archivelogin-help',
    name: 'archive-login-help',
    component: () => load(import('@/features/archivesaas/pages/LoginHelpPage')),
    meta: {
      title: 'archivesaas.loginHelp.title',
      authRequired: false,
    },
  },
  {
    path: '/activation',
    name: 'archive-activation',
    component: () =>
      load(import('@/features/archivesaas/pages/ActivationPage')),
    meta: {
      title: 'archivesaas.activation.title',
      clean: true,
      authRequired: false,
    },
  },
  {
    path: '/activationwizard',
    name: 'activation-wizard',
    component: () => load(import('@pages/ActivationWizardPage')),
    meta: {
      title: 'archivesaas.activation.title',
      clean: true,
      authRequired: true,
    },
  },
  {
    path: '/loginerror',
    name: 'loginerror',
    component: () => load(import('@pages/LoginErrorPage.vue')),
    meta: {
      title: 'router.routes.path.login.title',
      authRequired: false,
    },
  },
  {
    path: '/login/:tenant',
    name: 'login-tenant',
    props: true,
    component: () => load(import('@pages/LoginPage')),
    meta: {
      title: 'router.routes.path.login.title',
      authRequired: false,
    },
  },
  {
    path: '/login-help/:tenant',
    name: 'login-help-tenant',
    props: true,
    component: () => load(import('@pages/LoginHelpPage')),
    meta: {
      title: 'router.routes.path.loginHelp.title',
      authRequired: false,
    },
  },
  {
    path: '/changecustomer',
    name: 'changecustomer',
    beforeEnter: async (to, from, next) => {
      await sapi.landlord.post('user/login', {})
      next({ name: 'dashboard' })
    },
  },
  {
    path: '/',
    name: 'app',
    redirect: { name: 'login' },
    component: () => load(import('@views/AppView')),
    meta: { title: 'router.routes.path.app.title' },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => load(import('@pages/DashboardPage')),
        meta: {
          title: 'router.routes.path.dashboard.title',
          crumb: {
            i18n: 'router.routes.path.dashboard.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'recently-added',
        name: 'recently-added',
        component: () => load(import('@pages/EntityRecentPage')),
        meta: {
          title: 'router.routes.path.recentlyAdded.title',
          crumb: {
            i18n: 'router.routes.path.recentlyAdded.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'uploads',
        name: 'uploads',
        props: true,
        component: () => load(import('@pages/EntityUploadsPage')),
        meta: {
          title: 'router.routes.path.uploads.title',
          crumb: {
            i18n: 'router.routes.path.uploads.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'entity/:id',
        name: 'entity',
        props: route => ({
          id: route.params.id,
          typeId: route.query.type,
          displayId: route.query.display,
          autoStartEdit: !!route.query.edit,
          newValue: route.params.newValue,
        }),
        component: () => load(import('@pages/EntityDetailPage')),
        meta: {
          title: 'router.routes.path.entity.title',
          crumb: {
            i18n: 'router.routes.path.entity.crumb',
            parent: 'root',
            stack: true,
          },
        },
      },
      {
        path: 'entity/create',
        name: 'entity-create',
        props: route => ({
          typeId: route.params.type,
          displayId: route.params.display,
          folderId: route.params.folder,
          newValue: route.params.newValue,
        }),
        component: () => load(import('@pages/EntityDetailPage')),
        meta: {
          title: 'router.routes.path.create.title',
          crumb: {
            i18n: 'router.routes.path.create.crumb',
            parent: 'root',
            stack: true,
          },
        },
      },
      {
        path: 'folder/:id',
        name: 'folder',
        props: route => ({
          id: route.params.id,
          title: route.params.title,
        }),
        component: () => load(import('@pages/EntityFolderPage')),
        meta: {
          title: 'router.routes.path.folder.title',
          crumb: {
            i18n: 'router.routes.path.folder.crumb',
            parent: 'root',
            params: { stateless: true },
          },
        },
      },
      {
        path: 'search',
        name: 'search',
        props: route => ({
          query: route.query.query,
          sort: route.query.sort,
          title: route.params.title,
          stateless: route.params.stateless,
          syncQuery: true,
          showSearchTypes: true,
        }),
        component: () => load(import('@pages/EntitySearchPage')),
        meta: {
          title: 'router.routes.path.search.title',
          crumb: {
            i18n: 'router.routes.path.search.crumb',
            parent: 'root',
            params: { stateless: false },
          },
        },
      },
      {
        path: 'action/:id',
        name: 'action',
        props: route => ({
          id: route.params.id,
          stateless: route.params.stateless,
        }),
        component: () => load(import('@pages/ActionPage')),
        meta: {
          crumb: {
            parent: 'root',
            params: { stateless: false },
          },
        },
      },
      {
        path: 'action/:id/result',
        name: 'action-result',
        props: route => ({
          id: route.params.id,
          stateless: route.params.stateless,
          query: route.params.query,
        }),
        component: () => load(import('@pages/ActionPage')),
        meta: {
          crumb: {
            i18n: 'router.routes.path.action.result.crumb',
            parent: 'action',
            params: { stateless: false },
          },
        },
      },
      {
        path: 'processes',
        name: 'processes',
        component: () => load(import('@pages/PendingEntitiesPage')),
        meta: {
          title: 'router.routes.path.processes.title',
          crumb: {
            i18n: 'router.routes.path.processes.crumb',
            parent: 'root',
            params: { stateless: false },
          },
        },
      },
      {
        path: 'datacleanup',
        name: 'datacleanup',
        component: () => load(import('@pages/DataCleanupPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.datacleanup.title',
          crumb: {
            i18n: 'router.routes.path.datacleanup.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/idps',
        name: 'settings-idps',
        component: () => load(import('@pages/IdpTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.idps.title',
          crumb: {
            i18n: 'router.routes.path.idps.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/users',
        name: 'settings-users',
        component: () => load(import('@pages/UserTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.users.title',
          crumb: {
            i18n: 'router.routes.path.users.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/users/:id',
        name: 'settings-user',
        props: true,
        component: () => load(import('@pages/UserDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.user.title',
          crumb: {
            i18n: 'router.routes.path.user.crumb',
            parent: 'settings-users',
          },
        },
      },
      {
        path: 'settings/serviceaccounts',
        name: 'settings-serviceaccounts',
        component: () => load(import('@pages/ServiceAccountTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.serviceaccounts.title',
          crumb: {
            i18n: 'router.routes.path.serviceaccounts.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/serviceaccounts/:id',
        name: 'settings-serviceaccount',
        props: true,
        component: () => load(import('@pages/ServiceAccountDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.serviceaccount.title',
          crumb: {
            i18n: 'router.routes.path.serviceaccount.crumb',
            parent: 'settings-users',
          },
        },
      },
      {
        path: 'settings/roles',
        name: 'settings-roles',
        component: () => load(import('@pages/RoleTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.roles.title',
          crumb: {
            i18n: 'router.routes.path.roles.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/roles/:id',
        name: 'settings-role',
        props: true,
        component: () => load(import('@pages/RoleDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.role.title',
          crumb: {
            i18n: 'router.routes.path.role.crumb',
            parent: 'settings-roles',
          },
        },
      },
      {
        path: 'settings/appearance',
        name: 'settings-appearance',
        component: () => load(import('@pages/ConfigGeneralPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.appearance.title',
          crumb: {
            i18n: 'router.routes.path.appearance.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/themes',
        name: 'settings-themes',
        component: () => load(import('@pages/ConfigThemePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.themes.title',
          crumb: {
            i18n: 'router.routes.path.themes.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/actions',
        name: 'settings-actions',
        props: true,
        component: () => load(import('@pages/ActionTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.actions.title',
          crumb: {
            i18n: 'router.routes.path.actions.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/actions/:id',
        name: 'settings-action',
        props: route => ({
          id: route.params.id,
          parentActions: route.params.parentActions || [],
        }),
        component: () => load(import('@pages/ActionDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.action.title',
          crumb: {
            i18n: 'router.routes.path.action.crumb',
            parent: 'settings-actions',
          },
        },
      },
      {
        path: 'modeling/traits',
        name: 'modeling-traits',
        component: () => load(import('@pages/TraitTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.traits.title',
          crumb: {
            i18n: 'router.routes.path.traits.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'conditions',
        name: 'conditions',
        component: () => load(import('@pages/ConditionChecker')),
        meta: {
          admin: true,
          title: 'nav.item.condition.title',
          crumb: {
            i18n: 'nav.item.condition.title',
            parent: 'settings',
          },
        },
      },
      {
        path: 'modeling/traits/:id',
        name: 'modeling-trait',
        props: route => ({
          id: route.params.id,
          traits: route.params.traits || [],
        }),
        component: () => load(import('@pages/TraitDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.trait.title',
          crumb: {
            i18n: 'router.routes.path.trait.crumb',
            parent: 'modeling-traits',
          },
        },
      },
      {
        path: 'modeling/types',
        name: 'modeling-types',
        component: () => load(import('@pages/TypeTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.types.title',
          crumb: {
            i18n: 'router.routes.path.types.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'modeling/types/:id',
        name: 'modeling-type',
        props: route => ({
          id: route.params.id,
          types: route.params.types || [],
        }),
        component: () => load(import('@pages/TypeDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.type.title',
          crumb: {
            i18n: 'router.routes.path.type.crumb',
            parent: 'modeling-types',
          },
        },
      },
      {
        path: 'modeling/types/:typeId/displays/:id',
        name: 'modeling-display',
        props: true,
        component: () => load(import('@pages/DisplayDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.display.title',
          crumb: {
            i18n: 'router.routes.path.display.crumb',
            parent: 'modeling-type',
          },
        },
      },
      {
        path: 'modeling/types/:typeId/states/:id',
        name: 'modeling-states',
        props: true,
        component: () => load(import('@pages/StatesDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.states.title',
          crumb: {
            i18n: 'router.routes.path.states.crumb',
            parent: 'modeling-display',
          },
        },
      },
      {
        path: 'modeling/srvos',
        name: 'modeling-srvos',
        props: true,
        component: () => load(import('@pages/SrvoTablePage')),
        meta: {
          admin: true,
          title: 'router.routes.path.srvos.title',
          crumb: {
            i18n: 'router.routes.path.srvos.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'modeling/srvos/:id',
        name: 'modeling-srvo',
        props: true,
        component: () => load(import('@pages/SrvoDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.srvo.title',
          crumb: {
            i18n: 'router.routes.path.srvo.crumb',
            parent: 'modeling-srvos',
          },
        },
      },
      {
        path: 'settings/extensions',
        name: 'settings-extensions',
        props: true,
        component: () => load(import('@pages/ExtensionListPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.extensions.title',
          crumb: {
            i18n: 'router.routes.path.extensions.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/extensions/:id',
        name: 'settings-extension',
        props: true,
        component: () => load(import('@pages/ExtensionDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.extension.title',
          crumb: {
            i18n: 'router.routes.path.extension.crumb',
            parent: 'settings-extensions',
          },
        },
      },
      {
        path: 'customservices',
        name: 'custom-services',
        component: () => load(import('@pages/CustomServicesPage')),
        meta: {
          title: 'nav.group.customservices.title',
          crumb: {
            i18n: 'nav.group.customservices.title',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => load(import('@pages/SettingsPage')),
        meta: {
          title: 'nav.item.settings.title',
          crumb: {
            i18n: 'nav.item.settings.title',
            parent: 'root',
          },
        },
      },
      {
        path: 'dataport',
        name: 'dataport',
        component: () => load(import('@pages/DataportPage')),
        meta: {
          title: 'nav.item.dataport.title',
          crumb: {
            i18n: 'nav.item.dataport.title',
            parent: 'root',
          },
        },
      },
      {
        path: 'dataport/exports/:id',
        name: 'dataport-export',
        props: true,
        component: () => load(import('@pages/ExportDetailPage')),
        meta: {
          admin: true,
          title: 'router.routes.path.extension.title',
          crumb: {
            i18n: 'router.routes.path.extension.crumb',
            parent: 'settings-extensions',
          },
        },
      },
      {
        path: 'error/403',
        name: 'error-403',
        props: route => ({
          message: route.params.message,
        }),
        component: () => load(import('@pages/ErrorPage')),
        meta: {
          system: true,
          title: 'dm.error.403.name',
          crumb: {
            i18n: 'dm.error.403.name',
            parent: 'root',
          },
        },
      },
      {
        path: 'error/404',
        name: 'error-404',
        props: route => ({
          errorCode: 404,
          message: route.params.message,
        }),
        component: () => load(import('@pages/ErrorPage')),
        meta: {
          system: true,
          title: 'dm.error.404.name',
          crumb: {
            i18n: 'dm.error.404.name',
            parent: 'root',
          },
        },
      },
      {
        path: 'settings/licenses',
        name: 'licenses',
        component: () => load(import('@pages/LicensesPage')),
        meta: {
          title: 'nav.item.licenses.title',
          crumb: {
            i18n: 'nav.item.licenses.title',
            parent: 'settings',
          },
        },
      },
      {
        path: 'auditlog',
        name: 'audit-log',
        component: () => load(import('@pages/AuditLogPage')),
        meta: {
          title: 'nav.group.auditLog.title',
          crumb: {
            i18n: 'nav.group.auditLog.title',
            parent: 'root',
          },
        },
      },
      {
        path: 'upgrade',
        name: 'upgrade',
        component: () => load(import('@pages/UpgradeSubscriptionPage.vue')),
        meta: {
          title: 'nav.group.upgrade.title',
          crumb: {
            i18n: 'nav.group.upgrade.title',
            parent: 'root',
          },
        },
      },
      {
        path: 'usage',
        name: 'billing',
        component: () => load(import('@pages/BillingPage')),
        meta: {
          title: 'nav.group.billing.title',
          crumb: {
            i18n: 'nav.group.billing.title',
            parent: 'root',
          },
        },
      },
    ],
  },
]

if (process.env.VUE_APP_MODE === 'development') {
  routes.push({
    path: '/trial',
    name: 'trial',
    component: () => load(import('@pages/TrialPage')),
    meta: {
      title: 'router.routes.path.trial.title',
      authRequired: false,
    },
  })
}

merge(routes, gm)
export default routes

function merge(dst, src = []) {
  src.forEach(s => {
    const d = dst.find(e => e.name === s.name)
    if (!d) {
      dst.push(s)
    } else if (Array.isArray(s.children)) {
      d.children = d.children || []
      s.children.forEach(c => {
        d.children.push(c)
        merge(d.children, c.children)
      })
    }
  })
}
