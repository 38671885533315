import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import themes from '@styles/themes'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: themes,
  },
})
