import i18n from '@i18n'

import { setLabel } from './utils'
export default (model = {}) => {
  return setLabel(
    {
      ...{
        name: '',
        constraint: '',
        label: {},
        startStateId: '',
        endStateId: '',
        roleAccess: {
          ext_flowstate_assignee_role_key: true,
        },
        properties: {
          disabled: false,
          needsComment: false,
          successMessage: {
            en: i18n.t('dm.type.transitions.card.successMessage', 'en'),
            de: i18n.t('dm.type.transitions.card.successMessage', 'de'),
          },
          constraintError: {},
          redirect: 'entity',
        },
      },
      ...model,
    },
    'label'
  )
}
