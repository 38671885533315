/**
 * @module list
 * list operations
 */
const list = {
  /**
   * upsert of an item
   * @param {array} arr the array
   * @param {object} item the item
   * @param {string} [key=id] the key field
   */
  put: function (arr, item, key = 'id') {
    const idx = arr.findIndex(e => e[key] === item[key])
    if (idx > -1) {
      arr.splice(idx, 1, item)
    } else {
      arr.push(item)
    }
  },

  /**
   * splice/delete of an item
   * @param {array} arr the array
   * @param {object} item the item
   * @param {string} [key=id] the key field
   */
  destroy: function (arr, item, key = 'id') {
    const idx = arr.findIndex(e => e[key] === item[key])
    if (idx > -1) {
      arr.splice(idx, 1)
    }
  },

  /**
   * check if list contains any of the provided items
   * @param {array} arr the array
   * @param {...any} items the items to check
   * @returns {boolean} true if any of the items exist in the list
   */
  includesAny: function (arr, ...items) {
    return arr?.some(e => items.includes(e)) || false
  },

  /**
   * Merge specific attribute of two object array by comparing their id
   * @param {array} sourceArray the array of source object
   * @param {array} targetArray the array of target object that containt updated Attribute
   * @param {string} attributeName of attribute that will merged
   * @returns {array} that contraint merged attribut of both arrays
   */
  mergeArrayAttribute: function (sourceArray, targetArray, attributeName) {
    const idToObjectMap = {}
    for (const obj of sourceArray) {
      idToObjectMap[obj.id] = obj
    }
    const mergedArray = targetArray.map(targetObj => {
      const sourceObj = idToObjectMap[targetObj.id]
      if (sourceObj) {
        targetObj[attributeName] = Object.assign(
          targetObj[attributeName],
          sourceObj[attributeName]
        )
        return targetObj
      } else {
        return targetObj
      }
    })

    return mergedArray
  },
}

export default list
