import i18n from '@i18n'

import sapi from './service'

const endpoint = 'folders'

export default {
  async get(id) {
    const { data } = await sapi.webadapter.get(`${endpoint}/${id}`)
    return data
  },

  async getRoot() {
    const { data } = await sapi.webadapter.get(`${endpoint}`)
    return data
  },

  async getMetadata(id) {
    const { data } = await sapi.webadapter.get(`${endpoint}/${id}/metadata`)
    return data
  },

  async content(id, query) {
    const { data } = await sapi.webadapter.post(
      `${endpoint}/${id}/content`,
      query
    )
    return data
  },

  async create(item) {
    const { data } = await sapi.webadapter.post(endpoint, item)
    sapi.success(i18n.t('dm.folder.alert.folderCreated'))
    return data
  },

  async update(item) {
    await sapi.webadapter.put(`/${endpoint}/${item.id}`, item)
    sapi.success(i18n.t('dm.folder.alert.folderUpdated'))
  },

  async delete(id) {
    await sapi.webadapter.delete(`${endpoint}/${id}`)
  },

  async uploadDocument(entityId, folderId) {
    const { data } = await sapi.webadapter.post(
      `/${endpoint}/${folderId}/documents`,
      { recordId: entityId }
    )
    sapi.success(i18n.t('dm.folder.alert.documentCreated'))
    return data
  },

  async uploadMultipleDocuments(entityIds, folderId) {
    const { data } = await sapi.webadapter.post(
      `/${endpoint}/${folderId}/documents/batch`,
      entityIds
    )
    sapi.success(i18n.t('dm.folder.alert.documentCreated'))
    return data
  },

  async moveDocument(folderId, recordId, destination) {
    await sapi.webadapter.put(
      `/${endpoint}/${folderId}/documents/${recordId}`,
      { destination: destination }
    )
    sapi.success(i18n.t('dm.folder.alert.move.document'))
  },

  async moveFolder(folderId, folderName, destination) {
    await sapi.webadapter.put(`/${endpoint}/${folderId}`, {
      name: folderName,
      parentId: destination,
    })
    sapi.success(i18n.t('dm.folder.alert.move.folder'))
  },
}
