import i18n from '@i18n'

import model from './model'
import sapi from './service'

function users(name = 'customer') {
  return `${name}/users`
}

function tenantsettings(user) {
  return `tenant/users/${user}/settings`
}

export default {
  async get(id) {
    const { data } = await sapi.landlord.get(`${users()}/${id}`)
    return model.user(data)
  },

  async getTenantsettings(id) {
    if (id === 'new') {
      const data = model.userTenantsettings()
      data.roles.tenantuser = true
      return Promise.resolve(data)
    }
    const { data } = await sapi.landlord.get(tenantsettings(id))
    return model.userTenantsettings(data)
  },

  async list() {
    let { data } = await sapi.landlord.get(users('tenant'))
    data = data || []
    return data.map(e => model.user(e))
  },

  async create(item) {
    const { data } = await sapi.landlord.post(users(), item)
    sapi.success(
      i18n.t('dm.user.alert.created', {
        name: item.username,
      })
    )
    return model.user(data)
  },

  async update(item) {
    await sapi.landlord.put(`${users()}/${item.id}`, item)
    sapi.success(
      i18n.t('dm.user.alert.updated', {
        name: item.username,
      })
    )
    return model.user(item)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async saveTenantsettings(id, item) {
    await sapi.landlord.post(tenantsettings(id), item)
  },

  async delete(item) {
    await sapi.landlord.delete(`${users()}/${item.id}`)
    sapi.success(
      i18n.t('dm.user.alert.deleted', {
        name: item.username,
      })
    )
  },

  patchCurrentUser(body) {
    return sapi.landlord.patch('user', body, { omitError: true })
  },
}
