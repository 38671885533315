import i18n from '@i18n'

import model from './model'
import sapi from './service'

const typesEndpoint = 'types'
const statesEndpoint = 'states'
const configEndpoint = 'configuration'

export default {
  async list(typeId) {
    let { data } = await sapi.flow.get(
      `${typesEndpoint}/${typeId}/${statesEndpoint}`
    )
    data = data || []
    return data.map(e => model.flowState(e))
  },
  async create(item) {
    const { data } = await sapi.flow.post(
      `${typesEndpoint}/${item.typeId}/${statesEndpoint}`,
      item
    )
    sapi.success(
      i18n.t('dm.entityFlow.state.alert.created', {
        name: item.name,
      })
    )
    return model.flowState(data)
  },
  async update(item) {
    await sapi.flow.put(
      `${typesEndpoint}/${item.typeId}/${statesEndpoint}/${item.id}`,
      item
    )
    sapi.success(
      i18n.t('dm.entityFlow.state.alert.updated', {
        name: item.name,
      })
    )
    return model.flowState(item)
  },
  async updateConfig(item) {
    await sapi.flow.put(`${typesEndpoint}/${item.id}/${configEndpoint}`, item)
    return model.flowConfig(item)
  },
  async getConfig(typeId) {
    const { data } = await sapi.flow.get(
      `${typesEndpoint}/${typeId}/${configEndpoint}`
    )
    return model.flowConfig(data)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },
  async delete(item) {
    await sapi.flow.delete(
      `${typesEndpoint}/${item.typeId}/${statesEndpoint}/${item.id}`
    )
    sapi.success(
      i18n.t('dm.entityFlow.state.alert.deleted', {
        name: item.name,
      })
    )
  },
  async get(typeId, stateId) {
    const { data } = await sapi.flow.get(
      `${typesEndpoint}/${typeId}/${statesEndpoint}/${stateId}`
    )
    return model.flowState(data)
  },
}
