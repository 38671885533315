import api from '@api'

import model from '@api/model'

// also set in variables.scss $font-size-root
const defaultFontSize = '16px'

/**
 * Module for app style
 */
export default {
  namespaced: true,

  state: () => ({
    fontSize: defaultFontSize,
    fontSizes: ['14px', '15px', '16px', '17px', '18px'],
    colorScheme: '',
    colorSchemes: ['system', 'dark', 'light'],
    preferredDateFormat: undefined,
    preferredDateFormats: ['relative', 'absolute'],
  }),

  mutations: {
    fontSize(state, px) {
      state.fontSize = px
      localStorage.setItem('app/fontsize', px)
      const root = document.documentElement
      if (root) {
        root.style.fontSize = px
      }
    },
    colorScheme(state, color) {
      state.colorScheme = color
      localStorage.setItem('app/colorScheme', color)
    },
    preferredDateFormat(state, format) {
      state.preferredDateFormat = format
      localStorage.setItem('app/preferredDateFormat', format)
    },
  },

  actions: {
    init({ commit }) {
      const fontSize = localStorage.getItem('app/fontsize')
      const colorScheme = localStorage.getItem('app/colorScheme')
      if (fontSize) {
        commit('fontSize', fontSize)
      } else {
        commit('fontSize', defaultFontSize)
      }
      if (colorScheme) {
        commit('colorScheme', colorScheme)
      }
    },
    async initPreferredDateFormat({ commit, rootState, dispatch }) {
      const preferredDateFormat = localStorage.getItem(
        'app/preferredDateFormat'
      )

      const userConfig = rootState.app.context.userConfig
      if (
        preferredDateFormat &&
        userConfig?.preferredDateFormat !== preferredDateFormat
      ) {
        await dispatch('savePreferredDateFormat', preferredDateFormat)
      } else if (preferredDateFormat) {
        commit('preferredDateFormat', preferredDateFormat)
      } else if (!preferredDateFormat && userConfig?.preferredDateFormat) {
        commit('preferredDateFormat', userConfig.preferredDateFormat)
      }
    },
    async savePreferredDateFormat({ commit, rootState }, preferredDateFormat) {
      const userConfig = rootState.app.context.userConfig
      const updatedConfig = { ...userConfig, preferredDateFormat }
      await api.userConfig.save(model.userConfig(updatedConfig), true)
      commit('app/context/userConfig', updatedConfig, { root: true })
      commit('preferredDateFormat', preferredDateFormat)
    },
  },
}
