import user from '@gm/state/settings/user'

/**
 * Module for webscan settings
 */
export default {
  namespaced: true,

  modules: {
    user,
  },
}
