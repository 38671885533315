import api from '@api'

const DEFAULT_PAGINATION = {
  page: 1,
  pageCount: 1,
  pageStart: 0,
  pageStop: 0,
  itemsLength: 0,
  itemsPerPage: 10
}

export default {
  namespaced: true,

  state: () => ({
    result: {
      entities: []
    },
    scrollPos: 0,
    lastQuery: null,
    pageSize: 0,
    hasMoreHits: true,
    pagination: DEFAULT_PAGINATION,
    faceted: {
      model: {},
      types: [],
    },
    facetedFilter: true,
    searchableTypes: [],
    searchMask: {},
    displayedEntityID: undefined,
    tableState: {},
  }),

  mutations: {
    result(state, result) {
      state.result = result
    },
    scrollPos(state, pos) {
      state.scrollPos = pos
    },
    lastQuery(state, query) {
      state.lastQuery = query
    },
    hasMoreHits(state, hasMoreHits) {
      state.hasMoreHits = hasMoreHits
    },
    pageSize(state, pageSize) {
      state.pageSize = pageSize
    },
    pagination(state, options) {
      const { itemsPerPage, page } = options
      const { pagination } = state

      pagination.page = page ?? pagination.page
      pagination.itemsPerPage = itemsPerPage ?? pagination.itemsPerPage

      refreshPagination(state)
    },
    faceted(state, { key, value }) {
      state.faceted[key] = value
    },
    facetedFilter(state, facetedFilter) {
      state.facetedFilter = facetedFilter
    },
    searchableTypes(state, searchableTypes) {
      state.searchableTypes = searchableTypes
    },
    searchMask(state, value) {
      state.searchMask = value
    },
    displayedEntityID(state, id) {
      state.displayedEntityID = id
    },
    saveEntity(state, item) {
      const { result, result: { entities } = {} } = state
      const FOREIGN_ID_KEY = 'system.foreignId'
      const ROOT_ID_KEY = 'system.rootId'
      const VERSION_KEY = 'system.version'

      if (!entities || !item[FOREIGN_ID_KEY] || !result.types?.[item.type]) {
        return
      }

      let idx = entities.findIndex(entity => entity[FOREIGN_ID_KEY] === item[FOREIGN_ID_KEY])

      if (idx === -1 && item[VERSION_KEY]) {
        idx = entities.findIndex(
          entity => entity.id === item[ROOT_ID_KEY] || entity[ROOT_ID_KEY] === item[ROOT_ID_KEY]
        )
      }

      if (idx > -1) {
        entities.splice(idx, 1, item)
      } else {
        addEntityInResult(state, item)
      }
    },
    removeEntity(state, item) {
      const { result: { entities } = {} } = state
      const ID_KEY = 'id'

      if (!entities || !item[ID_KEY]) {
        return
      }

      const idx = entities.findIndex(entity => entity[ID_KEY] === item[ID_KEY])

      if (idx !== -1) {
        entities.splice(idx, 1)
        refreshPagination(state)
      }
    },
    tableState(state, options) {
      if(options.displayKey) {
        const { displayKey, ...props } = options
        state.tableState[displayKey] = props
      }
    },
    resetQuery(state, query) {
      state.result = {}
      state.lastQuery = query
      state.hasMoreHits = true
    }
  },

  actions: {
    async query({ commit, state }, query = {}) {
      commit('resetQuery', query)
      query.pageSize = state.pageSize

      try {
        const response = await api.search.search(query)
        const { nextPageToken, entities } = response

        if (!nextPageToken || entities.length < state.pageSize) {
          commit('hasMoreHits', false)
        }

        commit('result', response)
        return response
      } catch (err) {
        commit('result', {})
        throw err
      }
    },
    async queryTable({ commit, state, dispatch }, query = {}) {
      const { pagination } = state
      // load one more entity, then button next page will be enabled
      commit('pageSize', pagination.itemsPerPage + 1)
      commit('pagination', { ...pagination, page: 1 })
      await dispatch('query', query)
      commit('pageSize', pagination.itemsPerPage)
      commit('pagination', { ...pagination })
    },
    async queryCard({ commit, dispatch }, query = {}) {
      commit('pageSize', 20)
      await dispatch('query', query)
    },
    async loadNext({ state, commit, dispatch }, count = 0) {
      try {
        const pageSize = count || state.pageSize
        const query = { ...state.lastQuery }
        query.pageSize = pageSize
        if (state.result.nextPageToken) {
          query.pageToken = state.result.nextPageToken
        }
        const res = await api.search.search(query)
        if (!res.nextPageToken || res.entities.length < pageSize) {
          commit('hasMoreHits', false)
        }
        if (res.entities.length) {
          const oldCount = res.entities.length
          res.entities = res.entities.filter(
            entity => !state.result.entities.some(e => e.id === entity.id)
          )
          const newCount = res.entities.length
          res.entities = state.result.entities.concat(res.entities)
          res.types = { ...res.types, ...state.result.types }
          commit('result', res)
          // check "newCount > 0" is added to avoid infinite loop
          if (newCount < oldCount && state.hasMoreHits && newCount > 0) {
            return await dispatch('loadNext', oldCount - newCount)
          }
          return res
        } else {
          return state.result
        }
      } catch (err) {
        throw err
      }
    },
    async updateOptions({ commit, state, dispatch, rootGetters }, options = {}) {
      const { itemsPerPage, page } = options
      const { pagination, hasMoreHits, result} = state
      const tableConfigItemsPerPage = rootGetters['app/context/tableConfig']().itemsPerPage

      await dispatch('checkMoreResults')
      await dispatch('sort', options)

      // Check if itemsPerPage needs to be updated
      const itemsPerPageChanged = itemsPerPage !== pagination.itemsPerPage
      const userConfigChanged = pagination.itemsPerPage !== tableConfigItemsPerPage

      if (pagination.itemsPerPage && (itemsPerPageChanged || userConfigChanged)) {
        await dispatch('changeItemsPerPage', itemsPerPage)
      }

      // Check if page needs to be changed
      const isPageChanged = page !== pagination.page
      const isLastPage = result.entities.length < (page * pagination.itemsPerPage + 1)

      if (isPageChanged && hasMoreHits && isLastPage) {
        await dispatch('changePage', page)
      }

      commit('pagination', options)
      commit('tableState', options)
    },
    async sort({ commit, state, dispatch }, options = {}) {
      let sort = ''
      if (options.sortBy?.length) {
        sort = options.sortBy[0].replace(/\w*./, '')
        if (sort) {
          sort +=
            options.sortDesc[0] || options.sortDesc.length === 0
              ? ':desc'
              : ':asc'
        }
      }
      const resultSort = state.result?.sort?.length
        ? `${state.result.sort[0].field}:${state.result.sort[0].order}`
        : ''
      if (sort !== resultSort) {
        let sortIsPossible = true
        if (resultSort) {
          const attributes = state.result?.table?.attributes || []
          sortIsPossible = attributes.some(attr =>
            attr.id.includes(`.${state.result.sort[0].field}`)
          )
        }
        if (sortIsPossible || (!sortIsPossible && sort)) {
          const query = { ...state.lastQuery, sort }
          delete query.pageToken
          // load one more entity, then button next page will be enabled
          commit('pageSize', state.pagination.itemsPerPage + 1)
          await dispatch('query', query)
          commit('pageSize', state.pagination.itemsPerPage)
        }
      }
    },
    async changeItemsPerPage({ commit, state, dispatch }, itemsPerPage) {
      if (
        state.result.entities.length < itemsPerPage + 1 &&
        state.hasMoreHits
      ) {
        await dispatch(
          'loadNext',
          itemsPerPage - state.result.entities.length + 1
        )
      }
      commit('pageSize', itemsPerPage)
    },
    async changePage({ state, dispatch }, page) {
      const offset = page * state.pagination.itemsPerPage + 1 - state.result.entities.length
      const loadCount = offset !== state.pagination.itemsPerPage ? offset : undefined
      await dispatch('loadNext', loadCount)
    },
    async loadTypes({ commit, state }, forceLoad) {
      if (state.searchableTypes.length <= 0 || forceLoad) {
        const types = await api.entity.types()
        commit('searchableTypes', types)
      }
      return state.searchableTypes
    },
    async deleteEntity({ state, commit, dispatch }, item) {
      commit('removeEntity', item)
      if (state.hasMoreHits) {
        await dispatch('loadNext', 1)
      }
    },
    checkMoreResults({ state, commit }) {
      const { result } = state
      const hasMoreResults = result.nextPageToken && result.entities.length >= state.pageSize
      commit('hasMoreHits', hasMoreResults)
    }
  },

  getters: {
    getTableState: (state) => (tableId) => {
      return state.tableState[tableId] || {}
    }
  }
}

/**
 * Updates pagination details based on the current state and entities.
 *
 * @param {Object} state - The current state object.
 * @param {Object} state.pagination - Pagination details including `page`, `itemsPerPage`, and calculated fields.
 * @param {boolean} state.hasMoreHits - Indicates if more hits are available.
 * @param {Object} state.result - Contains the `entities` array.
 * @param {Array} state.result.entities - The list of current entities.
 *
 * @returns {void} Modifies the `pagination` object in the state directly.
 */
function refreshPagination(state) {
  const { pagination, hasMoreHits, result: { entities } = {} } = state
  const { page, itemsPerPage } = pagination
  const entitiesLength = entities.length

  pagination.pageCount = Math.ceil(entitiesLength / itemsPerPage)
  pagination.pageStart = (page - 1) * itemsPerPage
  pagination.pageStop = page * itemsPerPage

  if (pagination.pageStop > entitiesLength) {
    pagination.pageStop = entitiesLength
  }
  pagination.itemsLength = hasMoreHits ? `${entitiesLength - 1}+` : entitiesLength
}

/**
 * Adds an entity to `result.entities` based on sorting configuration and updates pagination.
 *
 * @param {Object} state - The current state.
 * @param {Object} state.result - Contains `entities` (array) and `sort` (array with field and order).
 * @param {boolean} state.hasMoreHits - Whether more hits are available.
 * @param {Object} item - The entity to add.
 *
 * @returns {void} Modifies `state.result.entities` directly.
 */
function addEntityInResult(state, item) {
  const { result, hasMoreHits } = state
  const entities = result.entities || []
  const sortField = result.sort?.[0]?.field
  const sortOrder = result.sort?.[0]?.order

  if (result.sort[0]) {
    if (sortField === 'created') {
      if (sortOrder === 'desc') {
        entities.unshift(item)
        refreshPagination(state)
      } else if (!hasMoreHits) {
        entities.push(item)
        refreshPagination(state)
      }
    } else {
      result.entities = []
    }
  }
}



