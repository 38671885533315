export default {
  namespaced: true,
  state: () => ({
    // colormode currently has to match the "PixelType" of Dynamsoft.
    // See: https://www.dynamsoft.com/web-twain/docs/info/api/Dynamsoft_Enum.html?ver=latest#dynamsoftdwtenumdwt_pixeltype
    profiles: [
      {
        name: 'blackwhite', // name is important for i18n
        colorMode: 0,
        dpi: 300,
      },
      {
        name: 'grayscale',
        colorMode: 1,
        dpi: 200,
      },
      {
        name: 'rgb',
        colorMode: 2,
        dpi: 200,
      },
    ],
    currentProfile: {},
  }),

  getters: {
    profiles: state => {
      return state.profiles
    },
    currentProfile: state => {
      if (Object.keys(state.currentProfile).length === 0) {
        return state.profiles.find(p => p.name === 'rgb')
      }
      return state.currentProfile
    },
    profileNames: state => {
      return state.profiles.map(p => p.display)
    },
  },

  mutations: {
    currentProfile: (state, profile) => {
      state.currentProfile = profile
    },
  },

  actions: {
    selectProfile({ commit }, profile) {
      commit('currentProfile', profile)
    },
  },
}
