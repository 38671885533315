import i18n from '@i18n'

import sapi from '@api/service'

const endpoint = 'archives'
const endpointCreateRetry = 'retries/creates'
const endpointDeleteRetry = 'retries/deletes'

export default {
  async get(id) {
    const { data } = await sapi.archivesaas.get(`${endpoint}/${id}`)
    return data
  },

  async list(params = {}) {
    let { data } = await sapi.archivesaas.get(endpoint, { params })
    data = data || []
    return data
  },

  async create(item) {
    const { data } = await sapi.archivesaas.post(endpoint, item, {
      omitError: true,
    })
    sapi.success(
      i18n.t('archivesaas.stores.storeCreated', {
        name: item.name,
      })
    )
    return data
  },

  async createUser(req) {
    const { data } = await sapi.archivesaas.post(
      `${endpoint}/${req.id}/users`,
      req,
      {
        omitError: true,
      }
    )
    sapi.success(
      i18n.t('archivesaas.stores.userCreated', {
        name: req.name,
      })
    )
    return data
  },

  async delete(item) {
    await sapi.archivesaas.delete(`${endpoint}/${item.id}`)
    sapi.success(
      i18n.t('archivesaas.stores.storeDeleted', {
        name: item.name,
      })
    )
  },

  async deleteUser(id, name) {
    const { data } = await sapi.archivesaas.delete(
      `${endpoint}/${id}/users/${name}`,
      {
        omitError: true,
      }
    )
    sapi.success(
      i18n.t('archivesaas.stores.userDeleted', {
        name: name,
      })
    )
    return data
  },

  async undoDelete(id) {
    await sapi.archivesaas.post(`${endpoint}/${id}/undo`)
    sapi.success(i18n.t('archivesaas.stores.undid'))
  },

  async retryDelete(id) {
    await sapi.archivesaas.post(`${endpointDeleteRetry}/${id}`)
    sapi.warning(i18n.t('archivesaas.stores.retried'))
  },

  async retryCreate(id) {
    await sapi.archivesaas.post(`${endpointCreateRetry}/${id}`)
    sapi.warning(i18n.t('archivesaas.stores.retried'))
  },
}
