import { clone } from 'lodash'

export function setLabel(model = {}, ...props) {
  props.forEach(prop => {
    if (typeof model[prop] === 'string') {
      model[prop] = { en: model[prop] }
    }
    if (!model[prop].en) {
      model[prop].en = ''
    }
  })
  return model
}

export function filterProps(model = {}, props = []) {
  const m = clone(model)
  props.forEach(prop => {
    if (m[prop]) {
      delete m[prop]
    }
  })
  return m
}
