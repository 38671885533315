<template>
  <v-overlay absolute>
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingPage',
}
</script>
