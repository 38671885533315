import i18n from '@i18n'

import api from '@/features/archivesaas/api/archives'
import sapi from '@api/system'

/**
 * Define initial state
 */
const DEFAULT_STATE = () => {
  return {
    list: [],
    item: [],
  }
}

/**
 * Define mutations
 */
const SET_LIST = 'SET_LIST'
const SET_ITEM = 'SET_ITEM'

/**
 * Initialize state
 */
export const state = DEFAULT_STATE

/**
 * Getters
 */
export const getters = {
  list: state => {
    return state.list
  },
  item: state => {
    return state.item
  },
}

/**
 * Mutations
 */
export const mutations = {
  [SET_ITEM](state, payload) {
    state.item = payload
  },
  [SET_LIST](state, payload) {
    state.list = payload
  },
}

/**
 * Actions
 */
export const actions = {
  async getItem({ commit }, id) {
    const item = await api.get(id)
    item.system = {}

    if (item.status !== 'ready' || !item.externalsystemid) {
      item.system.state = false
      item.system.errMessage = i18n.t(
        'archivesaas.stores.error.installationIncomplete'
      )
      item.system.lastCheck = new Date()
    } else {
      try {
        const sys = await sapi.get(item.externalsystemid)
        item.system.state = sys?.monitor?.healthStatus?.state
        item.system.errMessage = sys?.monitor?.healthStatus?.message
        item.system.lastCheck = sys?.monitor?.systemInfo?.lastCheck
      } catch (err) {
        item.system.state = false
        item.system.errMessage =
          i18n.t('archivesaas.stores.error.systemGone') + ' ' + err
        item.system.lastCheck = new Date()
      }
    }

    commit(SET_ITEM, item)
    return item
  },

  async getList({ commit }) {
    const [list, systems] = await Promise.all([api.list(), sapi.list()])

    systems.forEach(s => {
      let i = list.find(e => e.externalsystemid === s.id)
      if (i) {
        if (i.status === 'ready' && !s?.monitor?.healthStatus?.state) {
          i.status = 'error'
        }
      }
    })
    commit(SET_LIST, list)
    return list
  },

  async createItem({ commit }, payload) {
    const req = payload
    const item = await api.create(req)
    commit(SET_ITEM, item)
    return item
  },

  async undoDelete({ commit }, id) {
    await api.undoDelete(id)
    const item = await api.get(id)
    commit(SET_ITEM, item)
    return item
  },

  async retryDelete({ commit }, id) {
    await api.retryDelete(id)
    const item = await api.get(id)
    commit(SET_ITEM, item)
    return item
  },

  async retryCreate({ commit }, id) {
    await api.retryCreate(id)
    const item = await api.get(id)
    commit(SET_ITEM, item)
    return item
  },

  async createUser({ commit }, payload) {
    const req = payload
    const item = await api.createUser(req)
    commit(SET_ITEM, item)
    return item
  },

  async deleteUser({ commit }, payload) {
    const item = await api.deleteUser(payload.id, payload.name)
    commit(SET_ITEM, item)
    return item
  },
}
