/**
 * Module for removing entity
 */
export default {
  namespaced: true,

  state: () => ({
    recyclable: false,
    deletePermanently: false,
  }),

  mutations: {
    set(state, { prop, value }) {
      state[prop] = value
    },
    reset(state) {
      state.recyclable = false
      state.deletePermanently = false
    },
  },
}
