import i18n from '@i18n'

import sapi from '@api/service'

const endpoint = 'import'

export default {
  namespaced: true,

  actions: {
    async models({ commit }, systemId) {
      try {
        commit('app/view/loading', true, { root: true })
        const { data } = await sapi.architect.get(`${endpoint}/${systemId}`)
        sapi.success(i18n.t('dm.modeling.alerts.imported'))
        return data
      } finally {
        commit('app/view/loading', false, { root: true })
      }
    },
  },
}
