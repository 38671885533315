import { nanoid } from 'nanoid'
import Vue from 'vue'

import api from '@api'
import i18n from '@i18n'

/**
 * Module for custom events especially for custom content components
 * Provides cross communication with postMessage
 */
export default {
  namespaced: true,
  state: () => ({
    clientId: nanoid(),
    isInitialized: false,
    isActive: false,
    registeredComponents: [],
    entityValues: {},
    entityMain: {},
    entityCopy: {},
    attributes: [],
    listeners: new Map(),
    iframeOrigin: '*',
    allowedOrigins: [],
    customErrors: [],
  }),
  mutations: {
    initialized(state, isInitialized) {
      state.isInitialized = isInitialized
    },
    addCustomError(state, data) {
      const { sessionId, payload } = data

      if (!state.customErrors[sessionId]) {
        Vue.set(state.customErrors, sessionId, [])
      }
      let errors = state.customErrors[sessionId]
      errors.push(payload)
      Vue.set(state.customErrors, sessionId, errors)
    },
    removeCustomError(state, data) {
      const { sessionId, payload } = data
      const session = state.customErrors[sessionId]
      if (session) {
        const filter = session.filter(
          e => e.context.field !== payload.context.field
        )
        Vue.set(state.customErrors, sessionId, filter)
      }
    },
    setSlotProps(state, data) {
      state.entityMain = data.entityMain
      state.entityValues = data.entityValues
      state.entityCopy = data.entityCopy
      state.attributes = data.attributes
    },
    addComponent(state, data) {
      state.registeredComponents.push(data.iframe)
      state.isActive = true
    },
    removeComponent(state, ref) {
      state.registeredComponents = state.registeredComponents.filter(
        c => c.id !== ref.id
      )
      state.listeners.delete(ref.sessionId)
      delete state.customErrors[ref.sessionId]

      if (!state.registeredComponents.length) {
        state.isActive = false
      }
    },
    setEntityValues(state, values) {
      state.entityValues = values
    },
    updateEntityValues(state, obj) {
      for (let key in obj) {
        Vue.set(state.entityValues, key, obj[key])
      }
    },
    setAttributes(state, attr) {
      state.attributes = attr
    },
    addListener(state, data) {
      const { sessionId, payload, nonce } = data

      if (!state.listeners.has(sessionId)) {
        state.listeners.set(sessionId, [])
      }

      state.listeners.get(sessionId).push({
        nonce: nonce,
        action: payload.action,
      })
    },
    removeListener(state, data) {
      const { sessionId, payload } = data

      if (state.listeners.has(sessionId)) {
        const listeners = state.listeners.get(sessionId)
        const index = listeners.findIndex(
          listener =>
            listener.nonce === payload.nonce &&
            listener.action === payload.action
        )

        if (index !== -1) {
          listeners.splice(index, 1)
        }
      }
    },
  },
  actions: {
    init({ state, commit, dispatch }) {
      if (!state.isInitialized) {
        dispatch('registerEvents')
      }
      commit('initialized', true)
      return true
    },
    addComponent({ commit }, data) {
      if (data && data.iframe.sessionId) {
        commit('addComponent', data)
      } else {
        console.error('add custom content, no valid sessionId!')
      }
    },
    removeComponent({ commit }, ref) {
      if (ref && ref.sessionId) {
        commit('removeComponent', ref)
      } else {
        console.error('remove custom component, no valid sessionId!')
      }
    },
    setEntityValues({ commit }, values) {
      commit('setEntityValues', values)
    },
    registerEvents({ getters, dispatch }) {
      const messageEventHandler = evt => {
        if (!evt.data.sessionId) return
        if (evt.data && getters.isRegisteredComponent(evt.data.sessionId)) {
          handleAction(evt.data.action, evt.data)
        }
      }

      window.addEventListener('message', messageEventHandler, false)

      function handleAction(action, data) {
        const actionMap = {
          'app:getAccessToken': () => dispatch('sendAccessToken', data),
          'app:attributes': () => dispatch('sendAttributes', data),
          'app:entity:values': () => dispatch('sendEntityValues', data),
          'app:entity:attributes': () => dispatch('sendEntityAttributes', data),
          'component:register': () => dispatch('sendRegisterInfo', data),
          'component:listener:subscribe': () =>
            dispatch('listenerSubscribe', data),
          'component:listener:unsubscribe': () =>
            dispatch('listenerUnsubscribe', data),
          'component:entity:change': () => dispatch('updateEntity', data),
          'component:attribute:change': () => dispatch('updateAttribute', data),
          'component:notify': () =>
            dispatch('handleComponentNotification', data),
        }

        const actionHandler = actionMap[action]
        if (actionHandler) {
          actionHandler()
        }
      }
    },
    listenerSubscribe({ commit, dispatch }, data) {
      commit('addListener', data)

      const payload = {
        action: data.payload.action,
        nonce: data.nonce,
      }

      dispatch('sendEvent', {
        eventName: 'component:listener:subscribe',
        payload: payload,
        data,
      })
    },
    listenerUnsubscribe({ commit, dispatch }, data) {
      commit('removeListener', data)

      const payload = {
        action: data.payload.action,
        nonce: data.nonce,
      }

      dispatch('sendEvent', {
        eventName: 'component:listener:unsubscribe',
        payload: payload,
        data,
      })
    },
    sendRegisterInfo({ state, dispatch, rootState }, data) {
      const { sessionId, nonce } = data

      if (!nonce && !sessionId) {
        return false
      }

      const payload = {
        token: {
          refreshToken: api.auth.refreshToken,
          token: api.auth.token,
          auth_time: api.auth.tokenParsed.auth_time,
          expires: api.auth.tokenParsed.exp,
        },
        customer: rootState.app.context.tenant.customer,
        tenant: rootState.app.context.tenant.id,
        entity: state.entityValues,
        main: state.entityMain,
        copy: state.entityCopy,
        darkMode: rootState.app.theme.dark,
        lang: i18n.locale,
        colorSchemes: rootState.dm.config.cfg.theme,
        editing: rootState.app.detail.editing,
      }

      dispatch('sendEvent', {
        eventName: 'component:register',
        payload: payload,
        data,
      })
      return true
    },
    sendEntityValues({ state, dispatch }, data) {
      dispatch('sendEvent', {
        eventName: data.action,
        payload: state.entityValues,
        data,
      })
      return true
    },
    sendAccessToken({ _state, dispatch }, data) {
      dispatch('sendEvent', {
        eventName: 'app:getAccessToken',
        payload: {
          refreshToken: api.auth.refreshToken,
          token: api.auth.token,
          auth_time: api.auth.tokenParsed.auth_time,
          expires: api.auth.tokenParsed.exp,
        },
        data,
      })
      return true
    },
    sendAttributes({ state, dispatch }, data) {
      dispatch('sendEvent', {
        eventName: data.action,
        payload: state.attributes,
        data,
      })
      return true
    },
    sendEntityAttributes({ state, dispatch }, data) {
      const entityAttributes = state.attributes.filter(
        attr => attr.trait !== 'system'
      )
      dispatch('sendEvent', {
        eventName: data.action,
        payload: entityAttributes,
        data,
      })
      return true
    },
    sendEvent({ state }, { eventName, payload, data }) {
      const { sessionId, nonce } = data
      const { contentWindow } = state.registeredComponents.find(
        c => c.sessionId === sessionId
      )

      if (contentWindow && nonce) {
        const message = {
          action: eventName,
          payload,
          sessionId: sessionId,
          nonce: nonce,
        }
        contentWindow.postMessage(message, state.iframeOrigin)
      }
    },
    notifyListeners({ state, dispatch }, data) {
      const { action, payload } = data
      const listeners = state.listeners

      for (let [sessionId, sessionListeners] of listeners.entries()) {
        sessionListeners.forEach(listener => {
          if (listener.action === action) {
            dispatch('sendEvent', {
              eventName: action,
              payload: payload,
              data: {
                sessionId: sessionId,
                nonce: listener.nonce,
              },
            })
          }
        })
      }
    },
    setAttributes({ commit }, attr) {
      commit('setAttributes', attr)
    },
    updateEntity({ commit, dispatch, getters }, data) {
      if (
        data.action === 'component:entity:change' &&
        getters.isRegisteredComponent(data.sessionId)
      ) {
        commit('updateEntityValues', data.payload)

        dispatch('sendEvent', {
          eventName: data.action,
          payload: data.payload,
          data,
        })

        dispatch('notifyListeners', {
          action: 'app:entity:change',
          payload: data.payload,
        })
        return true
      }
    },
    updateSlotProps({ commit }, data) {
      commit('setSlotProps', data)
    },
    handleComponentNotification({ dispatch }, data) {
      const handlerMap = {
        error: {
          validation: () => dispatch('handleErrorValidation', data),
        },
        valid: {
          field: () => dispatch('handleValidField', data),
        },
      }
      const handler = handlerMap[data?.payload?.type][data?.payload?.reason]
      if (handler) {
        handler(data)
      }
      dispatch('sendEvent', {
        eventName: data.action,
        payload: { status: 'success' },
        data,
      })
      return true
    },
    handleErrorValidation({ commit }, data) {
      commit('addCustomError', data)
    },
    handleValidField({ commit }, data) {
      commit('removeCustomError', data)
    },
  },
  getters: {
    isRegisteredComponent: state => sessionId => {
      return state.registeredComponents.some(
        comp => comp.sessionId === sessionId
      )
    },
    getErrorMessagesById: state => id => {
      const messages = []
      Object.keys(state.customErrors).forEach(sessionId => {
        state.customErrors[sessionId].forEach(error => {
          if (error.context.field === id) {
            messages.push(error.message)
          }
        })
      })
      return messages
    },
  },
}
