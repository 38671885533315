/**
 * @module bundles
 * vuetify multi theme bundle configuration
 */

const SOFT = {
  label: 'Soft',
  light: {
    primary: '#0AA1E2',
    secondary: '#464646',
    background: '#F6F7F8',
    scrollbar: '#989898',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FF992B',
    highlight: '#DBFF00',
    variables: {
      '--es-font-family': 'Nunito',
      '--es-foreground-color': '#FFFFFF',
      '--es-border-radius-root': '8px',
      '--es-line-height': 1.5,
      '--es-fieldset-border': '1px solid rgba(00,00,00,0.20)',
      '--es-fieldset-bg-color': 'var(--es-foreground-color)',
    },
  },
  dark: {
    primary: '#0AA1E2',
    secondary: '#464646',
    background: '#000000',
    scrollbar: '#666666',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FF992B',
    highlight: '#DBFF00',
    variables: {
      '--es-font-family': 'Nunito',
      '--es-foreground-color': '#1E1E1E',
      '--es-border-radius-root': '8px',
      '--es-line-height': 1.5,
      '--es-fieldset-border': '1px solid rgba(00,00,00,0.20)',
      '--es-fieldset-bg-color': 'var(--es-foreground-color)',
    },
  },
}

const EASY = {
  label: 'Easy',
  light: {
    primary: '#000AE5',
    secondary: '#DADBF1',
    background: '#F6F7F8',
    scrollbar: '#989898',
    accent: '#82B1FF',
    error: '#DC2626',
    info: '#000AE5',
    success: '#16A34A',
    warning: '#D97706',
    highlight: '#DBFF00',
    variables: {
      '--es-font-family': 'EASY',
      '--es-foreground-color': '#FFFFFF',
      '--es-border-radius-root': '0px',
      '--es-line-height': 1.5,
      '--es-fieldset-border': 'none',
      '--es-fieldset-bg-color': 'var(--v-background-base)',
    },
  },
  dark: {
    primary: '#666DFF',
    secondary: '#393B66',
    background: '#1E293B',
    scrollbar: '#666666',
    accent: '#82B1FF',
    error: '#DC2626',
    info: '#000AE5',
    success: '#16A34A',
    warning: '#D97706',
    highlight: '#DBFF00',
    variables: {
      '--es-font-family': 'EASY',
      '--es-foreground-color': '#0F172A',
      '--es-border-radius-root': '0px',
      '--es-line-height': 1.5,
      '--es-fieldset-border': 'none',
      '--es-fieldset-bg-color': 'var(--v-background-base)',
    },
  },
}

const BUNDLES = { default: SOFT, novus: EASY }

export default BUNDLES
