/**
 * @module themes
 * vuetify theme configuration
 */
module.exports = {
  light: {
    primary: '#0AA1E2',
    secondary: '#464646',
    background: '#F6F7F8',
    scrollbar: '#989898',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FF992B',
    highlight: '#DBFF00',
  },
  dark: {
    primary: '#0AA1E2',
    secondary: '#464646',
    background: '#000000',
    scrollbar: '#666666',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FF992B',
    highlight: '#DBFF00',
  },
}
