import sapi from '@/api/service'
const endpoint = 'plans'

export default {
  async list(params = {}) {
    let { data } = await sapi.archivesaas.get(endpoint, { params })
    data = data || []
    return data
  },
}
