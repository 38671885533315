import { setLabel } from './utils'

export default (model = {}) => {
  return setLabel(
    {
      ...{
        name: '',
        group: '',
        label: {},
        readonly: false,
        traits: [],
        displays: [],
        constraints: [],
        deleteBehavior: 0,
        accessConstraint: '',
        storageSystem: 'internal',
        searchSystems: ['internal'],
        enableRetention: false,
        retention: {
          duration: 10,
          timeUnit: 1,
        },
        properties: {},
      },
      ...model,
    },
    'label'
  )
}
