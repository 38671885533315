import i18n from '@i18n'

import sapi from './service'

const userEndpoint = '/user/processes'
const adminEndpoint = '/admin/processes'

export default {
  async get(id) {
    try {
      const { data } = await sapi.webadapter.get(`${userEndpoint}/${id}`, {
        omitError: true,
      })
      return data
    } catch (e) {
      // Processes with state DONE are deleted since NDMS-6808.
      // So if response is 404 we just assume that it's done.
      if (sapi.isStatus(e, 404)) {
        return { id, state: 'DONE' }
      }
      throw e
    }
  },

  async list() {
    const { data } = await sapi.webadapter.get(userEndpoint)
    sortEntities(data.entities)
    return data
  },

  async listForAdmin() {
    const { data } = await sapi.webadapter.get(adminEndpoint)
    sortEntities(data.entities)
    return data
  },

  async create(templateId, entityId) {
    const { data } = await sapi.webadapter.post(userEndpoint, {
      templateId,
      entityId,
    })
    return data
  },

  async createAndSync(templateId, entity, copy) {
    try {
      const { data } = await sapi.webadapter.post(
        `${userEndpoint}/sync`,
        {
          templateId,
          entity,
          copy,
        },
        { omitError: true }
      )
      return data
    } catch (err) {
      if (err.response?.data?.message) {
        sapi.error(err, err.response.data.message)
      } else if (err.response?.data?.origin?.origin?.message) {
        sapi.error(err, err.response.data.origin.origin.message)
      } else {
        sapi.error(err)
      }
      return Promise.reject(err)
    }
  },

  async restart(processIds) {
    await sapi.webadapter.post('batch/processes/restart', {
      processIds,
    })
    sapi.success(
      i18n.t(
        `dm.processes.restarted.${processIds.length === 1 ? 'one' : 'more'}`,
        {
          count: processIds.length,
        }
      )
    )
  },
}

function sortEntities(entities) {
  if (Array.isArray(entities)) {
    entities.sort((a, b) => {
      a = a['system.lastmodified'] || a['system.created']
      b = b['system.lastmodified']
      return !b ? -1 : new Date(b) - new Date(a)
    })
  }
}
