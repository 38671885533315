import connect, { getDownloadLinks } from '@gm/utils/installer'

export default {
  namespaced: true,

  state: () => ({
    downloadLinks: {},
  }),

  getters: {
    downloadLinks: state => {
      return state.downloadLinks
    },
  },

  mutations: {
    downloadLinks: (state, downloadLinks) => {
      state.downloadLinks = downloadLinks
    },
  },

  actions: {
    async checkInstallation(context) {
      try {
        await connect()
      } catch (err) {
        if (
          !context.state.downloadLinks ||
          Object.keys(context.state.downloadLinks).length === 0
        ) {
          context.commit('downloadLinks', getDownloadLinks())
        }
        throw err
      }
    },
  },
}
