import i18n from '@i18n'

import sapi from './service'

function endpoint(entityId) {
  return entityId ? `records/${entityId}/blobs` : 'blobs'
}

export default {
  async src(file, entityId) {
    const data = await sapi.architect.blob.src(endpoint(entityId), file)
    return data
  },

  async text(file, entityId) {
    const data = await sapi.architect.blob.text(endpoint(entityId), file)
    return data
  },

  async download(file, entityId) {
    const data = await sapi.architect.blob.download(endpoint(entityId), file)
    return data
  },

  pdfjs(file, entityId) {
    return {
      url: `${sapi.architect.config.baseURL}/${endpoint(entityId)}/${file.id}`,
      httpHeaders: sapi.architect.headers(),
    }
  },

  /**
   * Generate preview for a file object
   * @param {object} file
   * @param {string} entityId
   * @param {object} opts - options to modify request behaviour
   * @param {number} opts.maxSize - max size of the file to preview in bytes, rejects promise if exceeded
   * @param {number} opts.timeout - timeout for the preview generation request
   * @returns {Promise<{hasPreviewSupport: (boolean|boolean), url: string}>}
   */
  async pdfPreview(
    file,
    entityId,
    { timeout = 15_000, maxSize = 15_000_000 } = {}
  ) {
    const info = await sapi.architect.blob.info(endpoint(entityId), file)
    if (maxSize < info.contentLength) {
      return Promise.reject(new Error('max size exceeded'))
    }

    const response = await sapi.architect.blob.preview(
      endpoint(entityId),
      file,
      { timeout, omitError: true }
    )
    const previewSupport = response.headers['x-es-previewsupported']
    return {
      url: URL.createObjectURL(response.data),
      hasPreviewSupport:
        previewSupport.toLowerCase() === 'false' ? false : !!previewSupport,
    }
  },

  async upload(file, suppress429Error = false) {
    try {
      const data = await sapi.architect.blob.upload(
        endpoint(),
        file,
        'dm/uploads/progress',
        true
      )
      sapi.success(
        i18n.t('dm.blob.alert.uploaded', {
          name: file.name,
        })
      )
      return data
    } catch (err) {
      if (!(suppress429Error && sapi.isStatus(err, 429))) {
        sapi.error(err)
      }
      return Promise.reject(err)
    }
  },
}
