import i18n from '@i18n'

import sapi from './service'

const endpoint = 'productinfos'
const endpointAddon = 'products'

export default {
  PRODUCT_IDS: Object.freeze({
    archivesaas: 'archivesaas',
    archivecloudnative: 'archivecloudnative',
  }),

  async get(id) {
    const { data } = await sapi.onboarding.get(`${endpoint}/${id}`, {
      omitError: true,
    })
    return data
  },
  async upgrade(id, item) {
    const { data } = await sapi.onboarding.post(
      `${endpointAddon}/${id}/addon`,
      item,
      {
        omitError: true,
      }
    )
    sapi.success(i18n.t('upgrade.subscription.confirm.success'))
    return data
  },
}
