import sapi from './service'

const endpoint = 'activations'

export default {
  async getStatus(id) {
    const { data } = await sapi.onboarding.get(`${endpoint}/${id}/status`, {
      omitError: true,
    })
    return data
  },
  async getStatusByCustomer(customerId) {
    const { data } = await sapi.onboarding.get(
      `${endpoint}/customer/${customerId}/status`,
      {
        omitError: true,
      }
    )
    return data
  },
  async execute(id, item) {
    const { data } = await sapi.onboarding.post(
      `${endpoint}/${id}/execute`,
      item,
      {
        omitError: true,
      }
    )
    return data
  },
  async complete(id) {
    const { data } = await sapi.onboarding.post(
      `${endpoint}/${id}/complete`,
      {},
      {
        omitError: true,
      }
    )
    return data
  },
  async reportError(id) {
    await sapi.onboarding.post(
      `${endpoint}/${id}/reporterror`,
      {},
      {
        omitError: true,
      }
    )
  },
}
