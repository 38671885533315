import gm from '@gm/api/services'

const webadapterUrl = process.env.VUE_APP_WEBADAPTER_URL

export default {
  webadapter: {
    local: !!webadapterUrl,
    url: webadapterUrl || 'webadapter/api/v1',
    apikey: '742625e71704304d231e9c09771ca653',
    system: 'easy1',
  },
  architect: {
    url: 'architect/api/v1',
    apikey: '71545a3c2b0490f3b12f7ca7e00c9557',
    system: 'easy1',
  },
  initiator: {
    url: 'initiator/api/v1',
    apikey: 'ee976b2222cb79b6301e787a6d3decc9',
    system: 'easy1',
  },
  janitor: {
    url: 'janitor/api/v1',
    apikey: 'cfa196cf501720db95f26bc97149767c',
    system: 'easy1',
  },
  // TODO NDMS-2797 apikey handling on hybrid connector side will be added once it use the DM service structure
  // this comment is there because the apikey may not be final - remove it after ticket resolution
  hybridconnector: {
    url: 'hybrid-connector/api/v1',
    apikey: '592ee8848ed2a917b81aa3063f0d9f76',
    system: 'easy1',
  },
  landlord: {
    url: 'landlord/api/v1',
    apikey: '0cfd839f90c55e9e58ef03f361052ff9',
    system: 'easy1',
  },
  extender: {
    url: 'extender/api/v1',
    apikey: '3d1532031d75b8bd3cde0d0224459a3a',
    system: 'easy1',
  },
  odata: {
    url: 'odata/ODataService.svc',
    apikey: '0480e233da464d77c351e81c219a2840',
    system: 'easy1',
  },
  onboarding: {
    url: 'onboarding/api/v1',
    apikey: '',
    system: 'easy1',
  },
  flow: {
    url: 'entityflow/api/v1',
    apikey: 'c1600faf542d701b23083167ac1faa84',
    system: 'easy1',
  },
  dataporter: {
    url: 'dataporter/api/v1',
    apikey: '',
    system: 'easy1',
  },
  ...gm,
}
