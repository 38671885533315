<template>
  <v-sparkline
    :value="values"
    :gradient="gradient()"
    gradient-direction="top"
    :auto-draw-duration="3000"
    line-width="1.5"
    smooth="10"
    padding="8"
    type="trend"
    auto-draw
  />
</template>

<script>
import gradients from '@assets/json/gradients.json'

export default {
  name: 'DashboardEmpty',
  data() {
    return {
      values: this.randoms(15, 0, 10),
    }
  },
  methods: {
    gradient() {
      return gradients[this.random(gradients.length)]
    },
    random(n) {
      return Math.floor(Math.random() * n)
    },
    randoms(len, start, end) {
      const res = [start]
      for (let i = 0; i < len - 2; i++) {
        res.push(this.random(10))
      }
      res.push(end)
      return res
    },
  },
}
</script>
