/**
 * This store is managing the state of the uploads
 * from the globally usable dropzone component
 */

import api from '@api'
import i18n from '@i18n'
import router from '@router'

import sapi from '@api/service'
import { isAnyFileSizeLimitReached } from '@utils/file'

/**
 * @typedef {{ id: string, name: string }} BlobResponse
 * @typedef {{ id: string | null, defaultType: string }} Folder
 * @typedef {{ isFolderView: boolean, subFolder: Folder} & Folder} FolderState
 * @typedef {'single' | 'multiple'} CreationMode
 * @typedef {{ files: FileList | File[], mode: CreationMode, targetFolder?: Folder | null }} FileSelection
 * @typedef {{ loading: boolean, name: string, file: File, progress: number }} FileUpload
 */
export default {
  namespaced: true,

  state: () => ({
    // present single/multi file upload
    showDialog: false,
    // files selected for upload
    fileSelection: {
      files: [],
      mode: 'single',
      targetFolder: null,
    },
    // flag if file upload is in progress
    uploading: false,
    // in flight file uploads
    uploadsInProgress: [],
    list: [],
    changes: [],
    unmodified: [],
    folderNeedsRefresh: false,
    folder: {
      id: '',
      isFolderView: false,
      defaultType: 'upload',
      subFolder: { id: null, defaultType: 'upload' },
    },
  }),
  mutations: {
    /**
     * Present the upload dialog for the provided files
     * @param state
     * @param files {FileList | File[]}
     * @param targetFolder {Folder | undefined}
     */
    showDialog(state, { files, targetFolder }) {
      state.showDialog = true
      state.fileSelection = {
        files: Array.from(files),
        mode: 'single',
        targetFolder,
      }
    },
    /**
     * Hide the upload dialog and reset the current file selection
     * @param state
     */
    hideDialog(state) {
      state.showDialog = false
      state.fileSelection = { files: [], mode: 'single', targetFolder: null }
    },
    /**
     * @param state
     * @param fileSelection {Partial<FileSelection>}
     */
    updateFileSelection(state, fileSelection) {
      state.fileSelection = {
        ...state.fileSelection,
        ...fileSelection,
        files: Array.from(fileSelection.files),
      }
    },
    resetFileSelection(state) {
      state.fileSelection = { files: [], mode: 'single', targetFolder: null }
    },
    /**
     * Started the file uploads
     * @param state
     */
    start(state) {
      state.uploading = true
    },
    /**
     * Finished uploading
     * @param state
     */
    finish(state) {
      state.uploading = false
      state.uploadsInProgress = []
      state.fileSelection = {
        files: [],
        mode: 'single',
      }
    },
    /**
     * Add files to prepare for upload
     * @param state
     * @param files {FileList | File[]} the selected files to upload
     */
    setFilesInProgress(state, files) {
      state.uploadsInProgress = Array.from(files).map(file => ({
        loading: true,
        name: file.name,
        file: file,
        progress: 0,
      }))
    },
    /**
     * Update the upload status for a given file
     * @param state
     * @param ev {{ percent: number, file: File }}
     */
    progress(state, ev) {
      const file = state.uploadsInProgress.find(file => ev.file === file)
      if (file) {
        file.progress = ev.percent
      }
    },
    add(state, items) {
      state.list = state.list.concat(items)
      updateUnmodified(state)
    },
    clear(state) {
      state.list = []
      state.changes = []
      state.unmodified = []
    },
    saved(state, item) {
      const foreignId = item['system.foreignId']
      if (state.changes.indexOf(foreignId) === -1) {
        state.changes.push(foreignId)
        updateUnmodified(state)
      }
    },
    folderNeedsRefresh(state, value) {
      state.folderNeedsRefresh = value
    },
    folder(state, options) {
      state.folder.id = options.id
      state.folder.isFolderView = options.isFolderView
      state.folder.defaultType = options.defaultType
      state.folder.subFolder.id = null
      state.folder.subFolder.defaultType = 'upload'
    },
    folderReset(state) {
      state.folder.id = ''
      state.folder.isFolderView = false
      state.folder.defaultType = 'upload'
      state.folder.subFolder.id = null
      state.folder.subFolder.defaultType = 'upload'
    },
    subFolder(state, options) {
      state.folder.subFolder.id = options.id
      state.folder.subFolder.defaultType = options.defaultType
    },
    subFolderReset(state) {
      state.folder.subFolder.id = null
      state.folder.subFolder.defaultType = 'upload'
    },
  },
  actions: {
    /**
     * Uploads given files and prompt the user if interaction is required
     * @param ctx
     * @param files {FileList | File[]}
     * @param targetFolder {Folder | undefined}
     * @returns {Promise<*>}
     */
    async uploadFilesInteractively(
      { commit, dispatch },
      { files, targetFolder }
    ) {
      if (files.length > 1) {
        return commit('showDialog', { files, targetFolder })
      }

      commit('updateFileSelection', {
        files: Array.from(files),
        mode: 'single',
        targetFolder,
      })

      return dispatch('uploadFiles')
    },
    /**
     * Upload current file selection.
     * Behaviour depends on whether current view is a folder view
     * and if one or multiple entities should be created
     * @param ctx
     */
    async uploadFiles({ commit, dispatch, state, rootGetters }) {
      const { fileSelection } = state
      if (!fileSelection.files?.length) {
        return
      }

      const limit = rootGetters['app/context/maxUploadSizeMB']()
      if (isAnyFileSizeLimitReached(fileSelection.files, limit)) {
        await showFileSizeWarning(dispatch, limit)
        return
      }

      commit('hideDialog')
      commit('start')
      commit('setFilesInProgress', fileSelection.files)

      try {
        const loadedFiles = await uploadBlobs(fileSelection.files)

        const folder = getCurrentFolder(state)
        if (fileSelection.targetFolder || folder.isFolderView) {
          await handleInFolderViewUpload(
            { commit },
            fileSelection.targetFolder || folder,
            loadedFiles,
            fileSelection.mode
          )
        } else {
          await handleDefaultUpload({ commit }, loadedFiles, fileSelection.mode)
        }
      } catch (err) {
        sapi.error(err)
      } finally {
        commit('finish')
      }
    },
    /**
     * Adds the provided entityIds to the currently selected folder
     * @param ctx
     * @param entityIds {string[]}
     * @returns {Promise<void>}
     */
    async addExistingEntitiesToFolder({ commit, state }, entityIds) {
      if (!entityIds?.length) {
        return
      }

      const result = await addEntitiesToFolder(
        getCurrentFolder(state),
        entityIds
      )

      for (const err of result.error) {
        if (
          err.result?.code === 400 &&
          err.result?.key === 'duplicate-record'
        ) {
          commit('app/alert/error', i18n.t('dm.folder.error.duplicateEntity'), {
            root: true,
          })
        } else {
          sapi.error(err.result)
        }
      }

      commit('subFolderReset')
      commit('folderNeedsRefresh', true)
    },
  },
}

/**
 * @param ctx
 * @param folder {Folder} the folder to attach entities to
 * @param loadedFiles {BlobResponse[]}
 * @param mode {CreationMode}
 * @returns {Promise<void>}
 */
async function handleInFolderViewUpload({ commit }, folder, loadedFiles, mode) {
  if (mode === 'multiple') {
    await addFilesToFolder(folder, loadedFiles)
    commit('subFolderReset')
    commit('folderNeedsRefresh', true)
  } else {
    await router.push({
      name: 'entity-create',
      params: {
        type: folder.defaultType,
        folder: folder.id,
        newValue: api.type.newBinaryFile(loadedFiles),
        display: 'default-edit',
      },
    })
  }
}

/**
 * @param ctx
 * @param loadedFiles {BlobResponse[]}
 * @param mode {CreationMode}
 * @returns {Promise<void>}
 */
async function handleDefaultUpload({ commit }, loadedFiles, mode) {
  if (mode === 'multiple') {
    commit('clear')
    /*
     * Entities for the provided loaded files are created
     * in the 'created' lifecycle of the EntityUploadsPage.
     *
     * To ensure this behavior is triggered we have to use this workaround
     */
    if (router.currentRoute.name === 'uploads') {
      await router.push({ name: 'dashboard' })
    }
    await router.push({
      name: 'uploads',
      params: {
        loadedFiles,
      },
    })
  } else {
    const { entityId } = await api.entity.save(api.type.getUpload(loadedFiles))
    await router.push({
      name: 'entity',
      params: {
        id: entityId,
      },
    })
  }
}

/**
 *
 * @param files {FileList | File[]}
 * @returns {Promise<BlobResponse[]>}
 */
async function uploadBlobs(files) {
  const responses = await Promise.all(
    Array.from(files).map(f => {
      return api.blob.upload(f)
    })
  )

  return responses.map(res => ({
    id: res.blobID,
    name: res.filename,
    contentLength: res.contentLength,
    contentType: res.contentType,
  }))
}

/**
 *
 * @param folder {Folder}
 * @param entityIds {string[]}
 * @returns{Promise<{ ok: any[], error: any[]}>}
 */
async function addEntitiesToFolder({ id }, entityIds) {
  const result = await api.folder.uploadMultipleDocuments(entityIds, id)

  return {
    ok: result.filter(e => !e.error),
    error: result.filter(e => e.error),
  }
}

function updateUnmodified(state) {
  state.unmodified = state.list.filter(
    e => state.changes.indexOf(e.values['system.foreignId']) === -1
  )
}

async function addFilesToFolder({ id, defaultType }, entities) {
  const items = await api.entity.batchCreate(
    entities.map(file => api.type.getUpload(file, defaultType))
  )

  showErrors(items)

  const ids = items.filter(i => !i.error).map(i => i.result.entityId)
  await api.folder.uploadMultipleDocuments(ids, id)
}

function getCurrentFolder(state) {
  return {
    id: state.folder.subFolder.id ? state.folder.subFolder.id : state.folder.id,
    isFolderView: state.folder.isFolderView,
    defaultType: state.folder.subFolder.id
      ? state.folder.subFolder.defaultType
      : state.folder.defaultType,
  }
}

function showErrors(list) {
  list.filter(e => e.error).forEach(e => sapi.error(e.result))
}

async function showFileSizeWarning(dispatch, limit) {
  await dispatch(
    'app/dialog/confirm',
    {
      name: 'fileSizeReached',
      options: {
        isWarning: true,
        placeholders: { limit },
      },
    },
    { root: true }
  )
}
