import moment from 'moment'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import gm from '@gm/i18n'

import de from './de.json'
import en from './en.json'

Vue.use(VueI18n)

const languages = {
  en: { ...en, gm: gm.en },
  de: { ...de, gm: gm.de },
}

/**
 * returns the initial client lang. either defined by the local storage
 * or set by the configured language of the underlying browser (if supported).
 */
function getLang() {
  const stored = localStorage.getItem('app/locale')
  let lang =
    stored ||
    (navigator.language || navigator.userLanguage).split('-')[0] ||
    'en'
  return languages[lang] ? lang : 'en'
}

/**
 * returns the local label for the object. the object can be either the direct
 * locale-object or an item which contains the locale-object in the property label.
 *
 * @param {{ label: object } | object | string} item the local-object or item
 * @param {string} [locale=current lang] the locale
 * @returns {string} the local label
 */
function getLabel(item, locale = i18n.locale) {
  if (!item) {
    return ''
  }

  if (typeof item === 'string') {
    return item
  }

  if (item['@type'] === 'i18n') {
    return item.label && item.label[locale] ? item.label[locale] : item.value
  }

  const target = item.label || item
  if (typeof target === 'string') {
    return target
  }

  return target[locale] || target.en || ''
}

/**
 * Add or update localization messages for a specific locale.
 *
 * @param {string} locale - The target locale for the localization messages.
 * @param {object} messages - The messages to be added or updated for the specified locale.
 */
function addLocalization(locale, messages) {
  const merged = Object.assign({}, this.messages[locale], messages)
  this.setLocaleMessage(locale, merged)
}

const lang = getLang()

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: languages,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  missing(locale, key) {
    if (key) {
      console.warn(`i18n :: key '${key}' not found for locale '${locale}'`)
    }
  },
})

// add custom functions
i18n.$l = getLabel
i18n.add = addLocalization
Vue.prototype.$l = getLabel

moment.locale(lang)

export default i18n
