import i18n from '@i18n'

import model from './model'
import sapi from './service'

const endpoint = 'tenant/roles'

export default {
  async get(id) {
    const { data } = await sapi.landlord.get(`${endpoint}/${id}`)
    return model.role(data)
  },

  async list() {
    let { data } = await sapi.landlord.get(endpoint)
    data = data || []
    return data.map(e => model.role(e))
  },

  async create(item, silent) {
    const { data } = await sapi.landlord.post(endpoint, item, {
      omitError: silent,
    })
    sapi.success(
      i18n.t('dm.role.alert.created', {
        name: item.name,
      })
    )
    return model.role(data)
  },

  async update(item, silent) {
    await sapi.landlord.put(`${endpoint}/${item.id}`, item, {
      omitError: silent,
    })
    sapi.success(
      i18n.t('dm.role.alert.updated', {
        name: item.name,
      })
    )
    return model.type(item)
  },

  async delete(item) {
    await sapi.landlord.delete(`${endpoint}/${item.id}`)
    sapi.success(
      i18n.t('dm.role.alert.deleted', {
        name: item.name,
      })
    )
  },

  async save(item, silent) {
    return item.id
      ? await this.update(item, silent)
      : await this.create(item, silent)
  },

  async setMembers(roleId, members) {
    const { data } = await sapi.landlord.put(
      `${endpoint}/${roleId}/members`,
      members
    )
    return data
  },

  async getMembers(roleId) {
    let { data } = await sapi.landlord.get(`${endpoint}/${roleId}/members`)
    return data
  },
}
