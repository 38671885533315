import i18n from '@i18n'

import { filterProps } from '@api/model/utils'
import sapi from '@api/service'
const endpointProvider = 'providers'
const endpointProfiles = 'profiles'
const endpointOauth = 'oauth2'
const isDev = process.env.NODE_ENV === 'development'

export default {
  async tryConnect(config) {
    const { data } = await sapi.mailfetcher.post(
      `${endpointProvider}/tryconnect`,
      config,
      {
        omitError: true,
      }
    )
    return data
  },

  async folders(config) {
    let { data } = await sapi.mailfetcher.post(
      `${endpointProvider}/folders`,
      config,
      {
        omitError: true,
      }
    )
    return data
  },

  async authUrl(provider) {
    let { data } = await sapi.mailfetcher.get(`${endpointOauth}/${provider}`, {
      params: { dev: isDev },
    })
    return data
  },

  async editTryConnect(id) {
    const { data } = await sapi.mailfetcher.get(
      `${endpointProfiles}/${id}/tryconnect`,
      {
        omitError: true,
      }
    )
    return data
  },

  async editFolders(id) {
    let { data } = await sapi.mailfetcher.get(
      `${endpointProfiles}/${id}/folders`,
      {
        omitError: true,
      }
    )
    return data
  },

  async get(id) {
    let { data } = await sapi.mailfetcher.get(`${endpointProfiles}/${id}`)
    return filterProps(data, ['created', 'creator', 'id', 'tenant'])
  },

  async list() {
    let { data } = await sapi.mailfetcher.get(endpointProfiles)
    return data
  },

  async create(config, state) {
    const { data } = await sapi.mailfetcher.post(endpointProfiles, config, {
      params: { state: state },
    })
    sapi.success(i18n.t('mailfetcher.ui.alert.created'))
    return filterProps(data, ['created', 'creator', 'tenant'])
  },

  async update(id, config, state) {
    const { data } = await sapi.mailfetcher.put(
      `${endpointProfiles}/${id}`,
      config,
      {
        params: { state: state },
      }
    )
    sapi.success(i18n.t('mailfetcher.ui.alert.updated'))
    return data
  },

  async delete(id) {
    await sapi.mailfetcher.delete(`${endpointProfiles}/${id}`)
    sapi.success(i18n.t('mailfetcher.ui.alert.deleted'))
  },
}
