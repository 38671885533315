import load from '@router/load'

export default [
  {
    path: 'settings/systems',
    name: 'settings-systems',
    component: () => load(import('@/features/systems/pages/SystemTablePage')),
    meta: {
      admin: true,
      title: 'router.routes.path.systems.title',
      crumb: {
        i18n: 'router.routes.path.systems.crumb',
        parent: 'root',
      },
    },
  },
  {
    path: 'settings/systems/:id',
    name: 'settings-system',
    props: true,
    component: () => load(import('@/features/systems/pages/SystemDetailPage')),
    meta: {
      admin: true,
      title: 'router.routes.path.system.title',
      crumb: {
        i18n: 'router.routes.path.system.crumb',
        parent: 'settings-systems',
      },
    },
  },
]
