/**
 * Module for the current detail view
 */
export default {
  namespaced: true,

  state: () => ({
    ready: false,
    valid: false,
    blocked: false,
    creating: false,
    editing: false,
    unsaved: false,
    changed: false,
    readonly: false,
    editable: true,
    deletable: true,
    activeTabs: {},
  }),

  mutations: {
    emit() {
      // for event subscription
    },
    set(state, { prop, value }) {
      state[prop] = value
    },
    reset(state) {
      Object.keys(state).forEach(key => {
        state[key] = false
      })
      state.activeTabs = {}
      state.editable = true
      state.deletable = true
    },
    setActiveTab(state, { id, name }) {
      state.activeTabs[id] = name
    },
  },
  getters: {
    activeTab: state => id => state.activeTabs[id],
  },
}
