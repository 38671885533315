import alert from '@state/modules/app/alert'
import context from '@state/modules/app/context'
import crumbs from '@state/modules/app/crumbs'
import customEvents from '@state/modules/app/customEvents'
import detail from '@state/modules/app/detail'
import dialog from '@state/modules/app/dialog'
import features from '@state/modules/app/features'
import login from '@state/modules/app/login'
import nav from '@state/modules/app/nav'
import remove from '@state/modules/app/remove'
import style from '@state/modules/app/style'
import telemetry from '@state/modules/app/telemetry'
import theme from '@state/modules/app/theme'
import trial from '@state/modules/app/trial'
import view from '@state/modules/app/view'

/**
 * Module for app
 */
export default {
  namespaced: true,

  modules: {
    alert,
    context,
    crumbs,
    customEvents,
    detail,
    dialog,
    features,
    login,
    nav,
    remove,
    style,
    telemetry,
    theme,
    trial,
    view,
  },
}
