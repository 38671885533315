import load from '@router/load'

export default [
  {
    name: 'app',
    children: [
      {
        path: 'gm/webscan/scan',
        name: 'gm-webscan-scan',
        component: () => load(import('@gm/pages/ScanSelectPage')),
        meta: {
          title: 'gm.router.routes.path.webscan.title',
          crumb: {
            i18n: 'gm.router.routes.path.webscan.crumb',
            parent: 'root',
          },
        },
      },
      {
        path: 'gm/webscan/document',
        name: 'gm-webscan-document',
        component: () => load(import('@gm/pages/ScanDocumentPage')),
        meta: {
          admin: false,
          title: 'gm.router.routes.path.webscan-document.title',
          crumb: {
            i18n: 'gm.router.routes.path.webscan-document.crumb',
            parent: 'gm-webscan-scan',
          },
        },
      },
    ],
  },
]
