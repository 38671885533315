export default (model = {}) => {
  if (Array.isArray(model.scope)) {
    if (!model.card && model.scope === 'card') model.card = {}
    if (!model.view && model.scope === 'edit') model.view = {}
    if (!model.view && model.scope === 'view') model.view = {}
  }
  if (model.view && !model.view.pages) {
    model.view.pages = []
  }
  return model
}
