import { setLabel } from './utils'

export default (model = {}) => {
  return setLabel(
    {
      ...{
        name: '',
        label: {},
      },
      ...model,
    },
    'label'
  )
}
