import i18n from '@i18n'

import model from './model'
import sapi from './service'

const endpoint = 'customer/identity-providers'
const queryTenantOnly = '?tenantOnly'

const providers = {
  azure: 'Azure',
  oidc: 'OpenID Connect',
}

export default {
  async get(id) {
    const { data } = await sapi.landlord.post(`${endpoint}/${id}`)
    return model.idp(data)
  },

  async list(tenantOnly = false) {
    const path = tenantOnly ? `${endpoint}${queryTenantOnly}` : endpoint
    let { data } = await sapi.landlord.get(path)
    data = data || []
    return data.map(e => model.idp(e))
  },

  async create(item) {
    const { data } = await sapi.landlord.post(endpoint, item)
    sapi.success(
      i18n.t('dm.idp.alert.created', {
        name: item.name,
      })
    )
    item.id = data.id
    return model.idp(data)
  },

  async update(item) {
    const { data } = await sapi.landlord.put(`${endpoint}/${item.id}`, item)
    sapi.success(
      i18n.t('dm.idp.alert.updated', {
        name: item.name,
      })
    )
    return model.idp(data)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async delete(item) {
    await sapi.landlord.delete(`${endpoint}/${item.id}`)
    sapi.success(
      i18n.t('dm.idp.alert.deleted', {
        name: item.name,
      })
    )
  },

  providers() {
    return Object.keys(providers).map(id => ({
      name: providers[id],
      id: id,
    }))
  },
}
