import sapi from './service'

const endpoint = 'search'

export default {
  async search(
    {
      query,
      sort,
      scope,
      displays,
      pageSize,
      pageToken,
      tableTemplate,
      faceted,
      facetedType,
      includeDeleted,
    },
    silent
  ) {
    const qry = {
      query: this.compiledQuery(query, faceted, facetedType),
      sort,
      scope,
      displays,
      pageSize,
      pageToken,
      tableTemplate,
      includeDeleted,
    }
    const { data } = await sapi.webadapter.post(endpoint, qry, {
      omitError: silent,
    })
    return data
  },

  compiledQuery(query, faceted, facetedType) {
    let ret = faceted ? `${faceted} ${query || ''}` : query
    if (facetedType) {
      ret = `${ret} @${facetedType}`
    }
    return ret
  },

  async count(query = '') {
    const { data } = await sapi.webadapter.post(`${endpoint}/count`, { query })
    return data?.count || 0
  },

  async countType(typeId) {
    const { data } = await sapi.webadapter.get(`${endpoint}/count/${typeId}`)
    return data?.count || 0
  },

  async countTypeAbs(typeId) {
    try {
      const { data } = await sapi.webadapter.get(
        `${endpoint}/count/${typeId}/abs`,
        { omitError: true }
      )
      return data?.count >= 0 ? data.count : -1
    } catch (e) {
      return -1
    }
  },
}
