import api from '@api'
import i18n from '@i18n'

import li from '@utils/list'

export default {
  namespaced: true,

  state: () => ({
    list: [],
  }),

  mutations: {
    set(state, list) {
      state.list = list
    },
    put(state, item) {
      li.put(state.list, item)
    },
    destroy(state, item) {
      li.destroy(state.list, item)
    },
  },

  actions: {
    async load({ commit }) {
      const list = await api.action.listForUser()
      commit('set', list)
    },
  },

  getters: {
    actions: state => scope => {
      const groups = {}
      state.list
        .filter(a => {
          return Array.isArray(a.scope) && a.scope.includes(scope)
        })
        .forEach(a => {
          const key = i18n.$l(a.group) + a.groupicon
          groups[key] = groups[key] || group(a)
          groups[key].items.push(item(a))
        })
      Object.keys(groups).forEach(k => {
        groups[k].items = groups[k].items.sort((a, b) => {
          return a.title.localeCompare(b.title)
        })
      })
      return Object.values(groups).sort((a, b) => {
        return a.title.localeCompare(b.title)
      })
    },
    findAction: state => action => {
      return state.list.find(a => {
        return a.action === action
      })
    },
    hasGroup: state => group => {
      return state.list.some(a => {
        return (
          typeof a.group === 'object' && Object.values(a.group).includes(group)
        )
      })
    },
  },
}

function group(action) {
  return {
    id: 'action-group-' + action.id,
    title: i18n.$l(action.group),
    icon: action.groupicon,
    items: [],
  }
}

function item(action) {
  return {
    id: 'action-item-' + action.id,
    title: i18n.$l(action.label),
    icon: action.icon,
    action: action,
  }
}
