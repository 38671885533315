import Vuetify from '@plugins/vuetify'
import bundles from '@styles/bundles'
import { hexcolor } from '@utils/regex'

/**
 * Module for app theme
 */
export default {
  namespaced: true,

  state: () => ({
    bundle: 'default',
    bundles: bundles,
    current: {},
    dark: false,
    scheme: 'light',
    editing: false,
  }),

  mutations: {
    dark(state, dark) {
      state.dark = dark
      state.scheme = dark ? 'dark' : 'light'
      localStorage.setItem('app/dark', dark)
    },
    bundle(state, bundle) {
      state.bundle = bundle
      state.current = state.bundles[state.bundle]
      localStorage.setItem('app/bundle', bundle)
    },
    bundles(state, bundles) {
      state.bundles = bundles
    },
    edit(state, flag) {
      state.editing = flag
    },
  },

  actions: {
    init({ commit }) {
      const dark = localStorage.getItem('app/dark')
      if (dark) {
        commit('dark', dark === 'true')
      } else if (prefersDark()) {
        commit('dark', true)
      }

      const bundle = localStorage.getItem('app/bundle')
      bundle !== null && commit('bundle', bundle)
    },
    setLightMode({ commit }) {
      commit('dark', false)
      if (this.$vuetify) {
        this.$vuetify.theme.dark = value
      }
      if (Vuetify.framework.theme) {
        Vuetify.framework.theme.dark = false
      }
    },
    set(_, { name, theme }) {
      const dark = name === 'dark'
      const vtheme = Vuetify.framework.theme
      assignTheme(vtheme.themes[name], theme)

      if ((dark && vtheme.dark) || (!dark && !vtheme.dark)) {
        assignTheme(vtheme.currentTheme, theme)
        assignVariables(theme.variables)
      }
      return Promise.resolve()
    },
    edit({ commit }, flag) {
      commit('edit', flag)
    },
    setDefaults({ dispatch }, config) {
      dispatch('setBundles', config.bundles)
      dispatch('setBundle', config.bundle)
    },
    setBundle({ commit, state }, name) {
      commit('bundle', name)

      const bundle = state.bundles[name]
      if (bundle?.font?.cssUrl) {
        loadFontCss(`bundle-font-${name}`, bundle.font.cssUrl)
      }
    },
    setBundles({ commit }, bundles) {
      commit('bundles', bundles)
    },
    setDark({ commit, state }, dark) {
      commit('dark', dark)
      const theme = dark ? 'dark' : 'light'
      const vars = state.bundles[state.bundle][theme].variables
      if (vars) {
        assignVariables(vars)
      }
    },
    setVariables(_, vars) {
      assignVariables(vars)
    },
  },
}

function assignTheme(dest, theme) {
  Object.keys(pickColors(theme)).forEach(key => {
    if (hexcolor.test(theme[key])) {
      dest[key] = theme[key]
    }
  })
}

function assignVariables(vars) {
  for (const [key, value] of Object.entries(vars)) {
    document.body.style.setProperty(`${key}`, `${value}`)
  }
}

function pickColors(theme) {
  const { primary, secondary, background, error, warning, success } = theme
  return { primary, secondary, background, error, warning, success }
}

function prefersDark() {
  return (
    window &&
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  )
}

function loadFontCss(id, url) {
  let linkEl = document.getElementById(id)
  if (linkEl) {
    return
  }

  if (!url || (!url.startsWith('https://') && !url.startsWith('/'))) {
    console.log('cannot load fonts with invalid url: ', url)
    return
  }

  linkEl = document.createElement('link')
  linkEl.setAttribute('id', id)
  linkEl.setAttribute('rel', 'stylesheet')
  linkEl.setAttribute('type', 'text/css')
  linkEl.setAttribute('href', url)
  document.head.appendChild(linkEl)
}
