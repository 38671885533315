const userAgent = navigator.userAgent.toLowerCase()
const arm64 = /arm64|aarch64/g.test(userAgent)
const mips64 = /mips64/g.test(userAgent)

function dynamsoft() {
  return window.Dynamsoft || { DWT: {} }
}

// function copied from webtwain.install.js
function getDebPath(strSuffix) {
  let _path = dynamsoft().DWT.ResourcesPath
  if (dynamsoft().Lib.isString(_path) && _path.length > 0) {
    if (_path[_path.length - 1] !== '/') {
      _path = _path + '/'
    }
  } else {
    _path = 'Resources/'
  }

  return [_path, 'dist/DynamsoftServiceSetup-', strSuffix, '.deb'].join('')
}

export function getDownloadLinks() {
  const isLinux = dynamsoft().navInfo.bLinux
  if (isLinux) {
    if (arm64) {
      return {
        default: getDebPath('arm64'),
      }
    } else if (mips64) {
      return {
        default: getDebPath('mips64el'),
      }
    }
    return {
      deb: dynamsoft().dcp.getLinuxHTML5DebPath(),
      rpm: dynamsoft().dcp.getLinuxHTML5RpmPath(),
    }
  }
  return {
    default: dynamsoft().dcp.getDSPath(),
  }
}

export default function connect() {
  return new Promise((resolve, reject) => {
    dynamsoft().DWT.CheckConnectToTheService(
      function () {
        resolve()
      },
      function () {
        reject(new Error('Could not connect to Service!'))
      }
    )
  })
}
