import load from '@router/load'

export default [
  {
    path: 'settings/mailfetcher/email',
    name: 'settings-mailfetcher-email',
    component: () => load(import('@mailfetcher/pages/ConfigMailAccountsPage')),
    meta: {
      admin: true,
      title: 'mailfetcher.router.routes.path.emailAccounts.title',
      crumb: {
        i18n: 'mailfetcher.router.routes.path.emailAccounts.crumb',
        parent: 'root',
      },
    },
  },
  {
    path: 'settings/mailfetcher/email/config/:id',
    name: 'settings-mailfetcher-email-config',
    component: () => load(import('@mailfetcher/pages/ConfigMailAccountPage')),
    props: true,
    meta: {
      admin: true,
      title: 'mailfetcher.router.routes.path.emailAccount.title',
      crumb: {
        i18n: 'mailfetcher.router.routes.path.emailAccount.crumb',
        parent: 'settings-mailfetcher-email',
      },
    },
  },
  {
    path: 'settings/mailfetcher/email/config/new',
    name: 'settings-mailfetcher-email-newConfig',
    component: () => load(import('@mailfetcher/pages/ConfigMailAccountPage')),
    meta: {
      admin: true,
      title: 'mailfetcher.router.routes.path.emailAccount.title',
      crumb: {
        i18n: 'mailfetcher.router.routes.path.emailAccount.crumb',
        parent: 'settings-mailfetcher-email',
      },
    },
  },
]
