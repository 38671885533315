/**
 * Module for snackbar alerts
 */
export default {
  namespaced: true,

  state: () => ({
    info: '',
    success: '',
    warning: '',
    error: '',
    sinfo: false,
    ssuccess: false,
    swarning: false,
    serror: false,
  }),

  mutations: {
    info(state, msg) {
      state.info = msg
      state.sinfo = true
    },
    success(state, msg) {
      state.success = msg || this._vm.$t('ui.alert.success')
      state.ssuccess = true
    },
    warning(state, msg) {
      state.warning = msg
      state.swarning = true
    },
    error(state, msg) {
      state.error = msg || this._vm.$t('ui.alert.error')
      state.serror = true
    },
    set(state, payload) {
      state[`s${payload.type}`] = payload.value || false
    },
  },
}
