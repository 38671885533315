import sapi from './service'

const endpoint = 'quota'

export default {
  async get() {
    const { data } = await sapi.webadapter.get(`${endpoint}`)
    return data
  },
  async batch(items = []) {
    const { data } = await sapi.webadapter.post(`batch/${endpoint}`, items)
    return data
  },
}
