import { setLabel } from './utils'

export default (model = {}) => {
  if (!model.attributes) {
    model.attributes = []
  }
  return setLabel(
    {
      ...{
        name: '',
        group: '',
        label: {},
        attributes: [],
        readonly: false,
      },
      ...model,
    },
    'label'
  )
}
