export default (model = {}) => {
  return {
    ...{
      accessibleDefault: true,
      proxyless: false,
      props: {},
      connection: { url: '' },
    },
    ...model,
  }
}
