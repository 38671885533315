import i18n from '@i18n'

import model from './model'
import sapi from './service'

function endpoint(res = 'templates') {
  return `admin/${res}`
}

export default {
  async get(id) {
    const { data } = await sapi.initiator.get(`${endpoint()}/${id}`)
    return model.srvo(data)
  },

  async list() {
    let { data } = await sapi.initiator.get(endpoint('templateinfos'))
    data = data || []
    return data.map(e => model.srvo(e))
  },
  async templates() {
    let { data } = await sapi.initiator.get(endpoint())
    data = data || []
    return data.map(e => model.srvo(e))
  },

  async create(item) {
    const { data } = await sapi.initiator.post(endpoint(), item)
    sapi.success(
      i18n.t('dm.srvo.alert.created', {
        name: item.name,
      })
    )
    item.id = data.id
    return model.srvo(item)
  },

  async update(item) {
    await sapi.initiator.put(`${endpoint()}/${item.id}`, item)
    sapi.success(
      i18n.t('dm.srvo.alert.updated', {
        name: item.name,
      })
    )
    return model.srvo(item)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async delete(item) {
    await sapi.initiator.delete(`${endpoint()}/${item.id}`)
    sapi.success(
      i18n.t('dm.srvo.alert.deleted', {
        name: item.name,
      })
    )
  },

  async triggers() {
    const { data } = await sapi.initiator.get(endpoint('triggers'))
    return data || []
  },

  async tasks() {
    const { data } = await sapi.initiator.get(endpoint('tasks'))
    return data || []
  },
}
