/* eslint-disable */
/**
 * Import core context
 */
import store from '@state/store'
import router from '@router'

import i18nInstance from '@i18n'

import i18n from './i18n'
import routes from './router/routes'
import state from './state'
import sapi from '@api/service'
import list from '@utils/list'

/**
 * Define feature constants
 */
const isDev = process.env.NODE_ENV === 'development' ? true : false
const VERSION = 'v0.1.1'
const FEATURE = 'archivesaas'
const NAME = 'Archive SaaS'
const ROUTER_NAME = 'archivesaas'

/**
 * Define feature info with details of registered components
 */
const INFO = {
  name: NAME,
  feature: FEATURE,
  version: VERSION,
  store: initStore(),
  routes: initRouter(),
  i18n: initI18n(),
  nav: initNav(),
  api: initApi(),
}

export default INFO

/**
 * Register and initialize api wrapper
 */
function initApi() {
  const servicedef = {
    url: 'archivesaas/api/v1',
    name: 'archivesaas',
    apikey: 'nokey',
    system: 'easy1',
  }
  sapi.register(servicedef)

  const status = sapi.archivesaas !== undefined
  return status
}
/**
 * Register and initialize store context
 */
function initStore() {
  store.debug = isDev
  store.registerModule(FEATURE, state)

  const status = store.hasModule(FEATURE)
  return status
}

/**
 * Register and initialize router context
 */
function initRouter() {
  routes.forEach(route => {
    router.addRoute('app', route)
  })

  const rr = router.getRoutes()
  const status = rr.some(route => route.name === 'archivesaas-stores')

  return status
}

/**
 *  Register and initialize localization context
 */
function initI18n() {
  Object.keys(i18n).forEach(key => {
    i18nInstance.add(key, { [FEATURE]: i18n[key] })
  })

  /**
   * TODO: check implementation
   */
  const status =
    i18nInstance.t('archivesaas.nav.title') !== 'archivesaas.nav.title'
  return status
}

/**
 *  Register and initialize navigation context
 */
function initNav() {
  store.commit('app/features/addNavEntry', {
    id: 'archivesaas',
    icon: 'mdi-archive',
    title: 'archivesaas.nav.title',
    matcher: 'archivesaas/[a-z]+|cna/[a-z]+',
    shouldBeShown: featureFlags =>
      list.includesAny(
        featureFlags,
        'dm-show-admin-menu-entry-archive-saas',
        'dm-show-admin-menu-entry-cna'
      ),
    admin: true,
    items: [
      {
        id: 'cna-stores',
        icon: 'mdi-store',
        title: 'archivesaas.cna.nav.store',
        route: { name: 'cna-stores' },
        shouldBeShown: featureFlags =>
          featureFlags.includes('dm-show-admin-menu-entry-cna'),
      },
      {
        id: 'archivesaas-stores',
        icon: 'mdi-store',
        title: 'archivesaas.nav.store',
        route: { name: 'archivesaas-stores' },
        shouldBeShown: featureFlags => {
          const msStoreLimit =
            store.state?.app?.context?.customerProperties
              ?.archiveStoreCreationLimit ?? 1
          return (
            msStoreLimit > 0 &&
            featureFlags.includes('dm-show-admin-menu-entry-archive-saas')
          )
        },
      },
      {
        id: 'archivesaas-modeling',
        icon: 'mdi-palette-advanced',
        title: 'archivesaas.nav.modeling',
        route: { name: 'settings-archivesaas-modeling' },
      },
    ],
  })
}
