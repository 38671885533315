class ScannedImage {
  constructor(blob, width = 2479, height = 3508) {
    this.blob = blob
    this.mimeType = blob.type
    this.height = height
    this.width = width
    this.checked = false
    this.selected = false

    this.url = window.URL.createObjectURL(this.blob)
  }

  /**
   * Creates a ScannedImage object from given URL.
   * @param url - URL of image
   * @param width - width of image
   * @param height - height of image
   * @returns {Promise<ScannedImage>} scanned image
   */
  static async createImage(url, width, height) {
    const blob = await fetch(url).then(response => response.blob())
    return new ScannedImage(blob, width, height)
  }

  deselect() {
    this.selected = false
  }

  /**
   * Needs to be called before image is deleted to free memory.
   */
  destroy() {
    window.URL.revokeObjectURL(this.url)
  }

  /**
   * Get blob of image.
   * @returns {Blob}
   */
  getBlob() {
    return this.blob
  }

  /**
   * Get height of image.
   * @returns {number} height of image
   */
  getHeight() {
    return this.height
  }

  /**
   * Get MIME type of image's blob.
   * @returns {string} MIME type of blob
   */
  getMIMEType() {
    return this.mimeType
  }

  /**
   * Get URL of image blob.
   * @returns {string} url of image
   */
  getUrl() {
    return this.url
  }

  /**
   * Get height of image.
   * @returns {number}
   */
  getWidth() {
    return this.width
  }

  isSelected() {
    return this.selected
  }

  select() {
    this.selected = true
  }

  toggleChecked() {
    this.checked = !this.checked
  }
}

export default ScannedImage
