export default (model = {}) => {
  return {
    ...{
      id: '',
      initialStateId: '',
      additionalAccessQuery: '',
      properties: {},
    },
    ...model,
  }
}
