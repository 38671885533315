import sapi from './service'

const endpoint = 'auditlogs'

export default {
  async search(filter = {}, limit = 0, skip = 0) {
    const { data } = await sapi.webadapter.post(`${endpoint}/search`, {
      filter,
      limit,
      skip,
    })
    return data
  },
}
