import i18n from '@i18n'

import model from './model'
import sapi from './service'

const endpoint = 'traits'

export default {
  async get(id) {
    const { data } = await sapi.architect.get(`${endpoint}/${id}`)
    return model.trait(data)
  },

  async list(withAttributes = false) {
    let { data } = await sapi.architect.get(endpoint)
    data = data || []
    if (!withAttributes) {
      data.forEach(e => delete e.attributes)
    }
    return data.map(e => model.trait(e))
  },

  async create(item) {
    const { data } = await sapi.architect.post(
      endpoint,
      this.removeUnusedProps(item)
    )
    sapi.success(
      i18n.t('dm.trait.alert.created', {
        name: item.name,
      })
    )
    return model.trait(data)
  },

  async update(item) {
    await sapi.architect.put(
      `${endpoint}/${item.id}`,
      this.removeUnusedProps(item)
    )
    sapi.success(
      i18n.t('dm.trait.alert.updated', {
        name: item.name,
      })
    )
    return model.trait(item)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async delete(item) {
    await sapi.architect.delete(`${endpoint}/${item.id}`)
    sapi.success(
      i18n.t('dm.trait.alert.deleted', {
        name: item.name,
      })
    )
  },

  async resolveSelectionList(
    traitId,
    attributeName,
    { pageToken, additionalQuery, entity, copy }
  ) {
    const options = {}
    options.pageToken = pageToken || ''
    options.additionalQuery = additionalQuery || ''
    options.entity = entity || {}
    options.copy = copy || {}
    try {
      const { data } = await sapi.webadapter.post(
        `${endpoint}/${traitId}/attribute/${attributeName}/selection`,
        options,
        {
          omitError: true,
        }
      )
      return data
    } catch (e) {
      if (
        e.response?.data?.origin?.key === 'mustache-render' &&
        e.response?.data?.origin?.origin?.message
      ) {
        sapi.warning(e.response.data.origin.origin.message)
      } else if (
        sapi.isStatus(e, 400) &&
        e.response?.data?.key === 'parse-query' &&
        e.response?.data?.message
      ) {
        sapi.error(e, e.response.data.message)
      } else {
        sapi.error(e)
      }
      return Promise.reject(e)
    }
  },

  async batchUpdate(traits) {
    const resp = await sapi.webadapter.post(`batch/${endpoint}/update`, traits)
    return resp.data
  },

  removeUnusedProps(item) {
    item.attributes.forEach(a => {
      this.DATATYPES.forEach(e => {
        if (a.datatype !== e.id) {
          delete a[e.id]
        }
      })
    })
    return item
  },

  DATATYPES: Object.freeze([
    { id: 'text', name: 'Text' },
    { id: 'boolean', name: 'Boolean' },
    { id: 'numeric', name: 'Numeric' },
    { id: 'date', name: 'Date' },
    { id: 'file', name: 'File' },
    { id: 'reference', name: 'Reference' },
    { id: 'nested', name: 'Nested' },
  ]),

  MEANINGS: Object.freeze({
    text: ['html', 'json', 'currency'],
    date: ['date', 'time', 'datetime'],
  }),

  SCOPES: Object.freeze([
    'faceted-search',
    'preview',
    'displays',
    'display-view',
    'display-edit',
    'display-card',
    'display-table',
  ]),

  BINARY: 'binary',
}
