export default (model = {}) => {
  return {
    ...{
      systems: [
        {
          id: 'internal',
          type: 'internal',
          name: 'Internal',
          level: 'fine',
        },
      ],
    },
    ...model,
  }
}
