export async function initManualAuth(auth, store, router) {
  window.addEventListener('auth:manual', function (event) {
    login({
      ...event.detail,
      auth,
      store,
      router,
    })
  })
}

async function login({
  token,
  refreshToken,
  realm,
  tenantId,
  clientId,
  actionId,
  auth,
  store,
  router,
}) {
  localStorage.setItem('app/dark', 'false')
  await store.dispatch('app/theme/setLightMode')

  if (auth && auth.clearToken) {
    auth.clearToken()
  }
  if (auth?.config?.realm) {
    delete auth.config.realm
  }
  await auth.load(realm, token, refreshToken, clientId)
  auth.authenticated = true
  await store.dispatch('app/context/load')
  await store.dispatch('app/context/hideMenus')
  await store.dispatch('app/context/switch', tenantId)
  if (actionId) {
    router.push(`/action/${actionId}`)
  }

  if (typeof window.flutter_inappwebview !== 'undefined') {
    window.flutter_inappwebview.callHandler('auth:manualDone', true)
  }
}

const manualAuth = (
  realm,
  tenantId,
  clientId,
  token,
  refreshToken,
  actionId
) => {
  window.dispatchEvent(
    new CustomEvent('auth:manual', {
      detail: {
        realm,
        tenantId,
        clientId,
        token,
        refreshToken,
        actionId,
      },
    })
  )
}

window.manualAuth = manualAuth
