import api from '@/features/archivesaas/api/plans'

/**
 * Define initial state
 */
const DEFAULT_STATE = () => {
  return {
    list: [],
  }
}

/**
 * Define mutations
 */
const SET_LIST = 'SET_LIST'

/**
 * Initialize state
 */
export const state = DEFAULT_STATE

/**
 * Getters
 */
export const getters = {
  list: state => {
    return state.list
  },
}

/**
 * Mutations
 */
export const mutations = {
  [SET_LIST](state, payload) {
    state.list = payload
  },
}

/**
 * Actions
 */
export const actions = {
  async getList({ commit, state }) {
    if (!state.list || state.list.length === 0) {
      const list = await api.list()
      commit(SET_LIST, list)
      return list
    }
    return state.list
  },
}
