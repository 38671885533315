export default (model = {}) => {
  return {
    ...{
      roles: {
        customeradmin: false,
      },
      federatedidentities: [],
      idp: '',
    },
    ...model,
  }
}
