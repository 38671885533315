import { setLabel } from './utils'

export default (model = {}) => {
  return setLabel(
    {
      ...{
        label: '',
        icon: '',
        group: '',
        groupicon: '',
        action: '',
        selectedFolder: {},
        scope: [],
        props: {},
        visibilityCondition: '',
      },
      ...model,
    },
    'label',
    'group'
  )
}
