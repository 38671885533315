<template>
  <b-page :title="$t('router.view.system.timeout.title')" fluid>
    <dashboard-empty />
  </b-page>
</template>

<script>
import BPage from '@components/base/BPage'
import DashboardEmpty from '@components/DashboardEmpty'

export default {
  name: 'Error408Page',
  components: {
    BPage,
    DashboardEmpty,
  },
}
</script>
