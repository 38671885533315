import i18n from '@i18n'

/**
 * Gets the localized folder name
 * Falls back to the technical name if no localisation is provided
 * @param {object} folder
 * @param {string} locale optional locale override
 * @returns {string} the folders display name
 */
export function getFolderDisplayName(folder, locale = i18n.locale) {
  if (!folder) {
    return ''
  }

  return i18n.$l(folder.label, locale) || folder.name || ''
}

export function sortFoldersByName(desc = false) {
  return (a, b) => {
    const nameA = getFolderDisplayName(a)
    const nameB = getFolderDisplayName(b)
    const comp = nameA.localeCompare(nameB)

    return desc ? -1 * comp : comp
  }
}
