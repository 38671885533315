import sapi from './service'

const endpoints = {
  exports: 'exports',
  config: 'config',
}

export default {
  async get(id) {
    const { data } = await sapi.dataporter.get(`${endpoints.exports}/${id}`)
    return data
  },

  async list() {
    let { data } = await sapi.dataporter.get(endpoints.exports)
    return data || []
  },

  async create(tenantId, password) {
    const { data } = await sapi.dataporter.post(endpoints.exports, {
      tenantId,
      password,
    })
    return data
  },

  async delete(id) {
    const { data } = await sapi.dataporter.delete(`${endpoints.exports}/${id}`)
    return data
  },

  async download({ exportId, downloadId, onDownloadProgress }) {
    const { data } = await sapi.dataporter.get(
      `${endpoints.exports}/${exportId}/downloads/${downloadId}`,
      {
        responseType: 'blob',
        onDownloadProgress,
      }
    )
    return data
  },

  async getConfig() {
    const { data } = await sapi.dataporter.get(endpoints.config)
    return data
  },
}
