import action from './action'
import attribute from './attribute'
import display from './display'
import flowConfig from './flowConfig'
import flowState from './flowState'
import flowTransition from './flowTransition'
import idp from './idp'
import page from './page'
import role from './role'
import serviceaccount from './serviceaccount'
import srvo from './srvo'
import system from './system'
import trait from './trait'
import type from './type'
import typeCompat from './typeCompat'
import typeConfig from './typeConfig'
import user from './user'
import userConfig from './userConfig'
import userTenantsettings from './userTenantsettings'
import view from './view'

export default {
  action,
  attribute,
  display,
  flowConfig,
  flowState,
  flowTransition,
  idp,
  page,
  role,
  serviceaccount,
  srvo,
  system,
  trait,
  type,
  typeCompat,
  typeConfig,
  user,
  userTenantsettings,
  view,
  userConfig,
}
