import i18n from '@i18n'

import store from '@state/store'

import model from './model'
import sapi from './service'

const endpoint = 'types'

function config(type) {
  return `types/${type}/config`
}

function compat(type) {
  return `types/${type}/compatibility`
}

function createDisplayPath(type) {
  return `types/${type}/displays?scope=edit&findfirst=true`
}

export default {
  async get(id, evaluate, omitError = false) {
    const params = evaluate ? { evaluate } : {}
    const { data } = await sapi.architect.get(`${endpoint}/${id}`, {
      params,
      omitError,
    })
    return model.type(data)
  },

  async getConfig(id) {
    if (id === 'new') {
      return model.typeConfig()
    }
    try {
      const { data } = await sapi.architect.get(config(id), {
        omitError: true,
      })
      return model.typeConfig(data)
    } catch (err) {
      if (sapi.isStatus(err, 404)) {
        return model.typeConfig()
      } else {
        sapi.error(err)
      }
    }
  },

  async getCompat(id) {
    if (id === 'new') {
      return model.typeCompat()
    }
    try {
      const { data } = await sapi.architect.get(compat(id), {
        omitError: true,
      })
      return model.typeCompat(data)
    } catch (err) {
      if (sapi.isStatus(err, 404)) {
        return model.typeCompat()
      } else {
        sapi.error(err)
      }
    }
  },

  getUpload(file, type) {
    const binaryFile = this.newBinaryFile(file)
    return {
      type: type ? type : store.state.dm.config.cfg.defaultUpload,
      ...binaryFile,
    }
  },

  newBinaryFile(file) {
    return {
      'binary.file': {
        '@type': 'file',
        value: file,
        contentLength: file.contentLength,
        contentType: file.contentType,
      },
    }
  },

  async list() {
    let { data } = await sapi.architect.get(endpoint)
    data = data || []
    return data.map(e => model.type(e))
  },

  async getCreateDisplay(id) {
    try {
      let { data } = await sapi.webadapter.get(createDisplayPath(id), {
        omitError: true,
      })
      return data
    } catch (e) {
      return null
    }
  },

  async getInUserScope(id) {
    return await sapi.webadapter.get(`${endpoint}/${id}`, {
      omitError: true,
    })
  },

  async create(item) {
    const { data } = await sapi.architect.post(endpoint, item)
    sapi.success(
      i18n.t('dm.type.alert.created', {
        name: item.name,
      })
    )
    return model.type(data)
  },

  async update(item) {
    await sapi.architect.put(`${endpoint}/${item.id}`, item)
    sapi.success(
      i18n.t('dm.type.alert.updated', {
        name: item.name,
      })
    )
    return model.type(item)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async saveConfig(id, data) {
    await sapi.architect.put(config(id), data)
  },

  async delete(item) {
    await sapi.architect.delete(`${endpoint}/${item.id}`)
    sapi.success(
      i18n.t('dm.type.alert.deleted', {
        name: item.name,
      })
    )
  },

  async patchAll(items) {
    const resp = await sapi.webadapter.patch(endpoint, items)
    return resp.data
  },

  async listConstraints() {
    const { data } = await sapi.architect.get('constraints', {
      omitError: true,
    })
    return data
  },
}
