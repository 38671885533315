import load from '@router/load'

export default [
  {
    path: 'settings/cna/stores',
    name: 'cna-stores',
    component: () =>
      load(import('@/features/archivesaas/pages/CloudNativeStoresTablePage')),
    meta: {
      admin: true,
      title: 'archivesaas.nav.title',
      crumb: {
        i18n: 'archivesaas.stores.crumb',
        parent: 'root',
      },
    },
  },
  {
    path: 'settings/cna/stores/:id',
    name: 'cna-stores-detail',
    props: true,
    component: () =>
      load(import('@/features/archivesaas/pages/CloudNativeStoresDetailPage')),
    meta: {
      admin: true,
      title: 'archivesaas.cna.nav.title',
      crumb: {
        i18n: 'archivesaas.cna.stores.crumb',
        parent: 'cna-stores',
      },
    },
  },
  {
    path: 'settings/archivesaas/stores',
    name: 'archivesaas-stores',
    component: () =>
      load(import('@/features/archivesaas/pages/StoresTablePage')),
    meta: {
      admin: true,
      title: 'archivesaas.nav.title',
      crumb: {
        i18n: 'archivesaas.stores.crumb',
        parent: 'root',
      },
    },
  },
  {
    path: 'settings/archivesaas/stores/:id',
    name: 'archivesaas-stores-detail',
    props: true,
    component: () =>
      load(import('@/features/archivesaas/pages/StoreDetailPage')),
    meta: {
      admin: true,
      title: 'archivesaas.nav.title',
      crumb: {
        i18n: 'archivesaas.stores.crumb',
        parent: 'archivesaas-stores',
      },
    },
  },
  {
    path: 'settings/archivesaas/stores/_request',
    name: 'archivesaas-stores-request',
    component: () =>
      load(import('@/features/archivesaas/pages/RequestNewStorePage')),
    meta: {
      admin: true,
      title: 'archivesaas.stores.request.title',
      crumb: {
        i18n: 'archivesaas.stores.request.crumb',
        parent: 'archivesaas-stores',
      },
    },
  },
  {
    path: 'settings/archivesaas/stores/:id/userrequest',
    name: 'archivesaas-stores-request-user',
    props: true,
    component: () =>
      load(import('@/features/archivesaas/pages/RequestNewUserPage')),
    meta: {
      admin: true,
      title: 'archivesaas.stores.request.title',
      crumb: {
        i18n: 'archivesaas.stores.request.crumb',
        parent: 'archivesaas-stores-detail',
      },
    },
  },
  {
    path: 'settings/archivesaas/modeling',
    name: 'settings-archivesaas-modeling',
    component: () => load(import('@/features/archivesaas/pages/ModelingPage')),
    meta: {
      admin: true,
      title: 'archivesaas.modeling.title',
      crumb: {
        i18n: 'archivesaas.modeling.title',
        parent: 'root',
      },
    },
  },
  {
    path: 'settings/archivesaas/modeling/:id',
    name: 'settings-archivesaas-modeling-detail',
    props: route => ({
      id: route.params.id,
    }),
    component: () =>
      load(import('@/features/archivesaas/pages/ModelingDetailPage')),
    meta: {
      crumb: {
        parent: 'settings-archivesaas-modeling',
      },
    },
  },
]
