/**
 * @module regex
 * regular expressions
 */

// email expression
export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// hexcolor code expression. only supports triplet format
export const hexcolor = /^#[a-fA-F0-9]{6}$/

// technical name for trait, types and orchestration
export const technicalName = /^[A-Za-z0-9_]+$/

// numeric value of trait attribute
export const numeric = /^(-|\+)?[0-9]\d*(\.\d*)?$/

export const integer = /^([+-]?[1-9]\d*|0)$/
