import load from '@router/load'

export default [
  {
    path: 'settings/cloudarchive',
    name: 'settings-cloudarchive',
    component: () => load(import('@pages/SettingsPage')),
    meta: {
      admin: true,
      title: 'nav.item.settings.title',
      crumb: {
        i18n: 'nav.item.settings.title',
        parent: 'root',
      },
    },
  },
]
