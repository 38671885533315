export default (model = {}) => {
  return {
    ...{
      id: '',
      name: '',
      provider: '',
      enabled: true,
      internal: false,
      config: {
        tenant: 'common',
        clientId: '',
        clientSecret: '',
        tokenUrl: '',
        logoutUrl: '',
        authorizationUrl: '',
      },
    },
    ...model,
  }
}
