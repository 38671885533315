import api from '@api'

const SET_EXTENSIONS = 'SET_EXTENSIONS'
const SET_REGISTRATIONS = 'SET_REGISTRATIONS'
const SET_STATUS = 'SET_STATUS'

export default {
  namespaced: true,

  state: () => ({
    active: [],
    activated: {},
    extensions: [],
    registrations: [],
  }),

  mutations: {
    [SET_EXTENSIONS](state, list) {
      state.extensions = list
    },

    [SET_REGISTRATIONS](state, list) {
      state.registrations = list
      state.active = list.map(e => e.extension.name)
    },

    [SET_STATUS](state, extension) {
      state.activated[extension.name] = extension.value
    },
  },

  actions: {
    async load({ commit, getters }) {
      if (getters.isAdmin() || getters.isCustomerAdmin()) {
        const extensions = await api.extension.loadExtensions()
        const registrations = await api.extension.loadRegistrations()
        commit(SET_EXTENSIONS, extensions)
        commit(SET_REGISTRATIONS, registrations)
      }
    },

    setExtensions({ commit }, data) {
      commit(SET_EXTENSIONS, data)
    },

    setRegistrations({ commit }, data) {
      commit(SET_REGISTRATIONS, data)
    },

    async isActiveByName({ state, commit }, name) {
      /**
       * to avoid fetch every time the status of an extension (workaround)
       * should be solved with single endpoint to get status for all extensions
       * also response of getStatusByName of non existings should not be resolved with 404 error
       * extender needs several improvements!
       */
      if (state.activated[name]) {
        return state.activated[name]
      } else {
        const status = await api.extension.getStatusByName(name)
        const isActive = status === 'activated'
        commit(SET_STATUS, { name: name, value: isActive })
        return isActive
      }
    },
  },

  getters: {
    experimental: state => {
      return state.extensions.filter(
        e =>
          e.maturityState === api.extension.STATES['EXPERIMENTAL'].toLowerCase()
      )
    },

    beta: state => {
      return state.extensions.filter(
        e => e.maturityState === api.extension.STATES['BETA'].toLowerCase()
      )
    },

    stable: state => {
      return state.extensions.filter(
        e => e.maturityState === api.extension.STATES['STABLE'].toLowerCase()
      )
    },

    hasExperimentalAccess: (state, getters, rootState, rootGetters) => {
      return rootGetters['app/context/hasExperimentalAccess']
    },

    isAdmin: (state, getters, rootState, rootGetters) => {
      return rootGetters['app/context/isAdmin']
    },

    isCustomerAdmin: (state, getters, rootState, rootGetters) => {
      return rootGetters['app/context/isCustomerAdmin']
    },
  },
}
