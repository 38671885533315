/* eslint-disable */
import vue from 'vue'

/**
 * Import core context
 */
import store from '@state/store'
import router from '@router'

import routes from './router/routes'

/**
 * Define feature constants
 */
const VERSION = 'v0.1.1'
const FEATURE = 'externalSystems'
const NAME = 'Systems'

/**
 * Define feature info with details of registered components
 */
const INFO = {
  name: NAME,
  feature: FEATURE,
  version: VERSION,
  routes: initRouter(),
  nav: initNav(),
}

export default INFO

/**
 * Register and initialize router context
 */
function initRouter() {
  routes.forEach(route => {
    router.addRoute('app', route)
  })

  const rr = router.getRoutes()
  const status = rr.some(route => route.name === 'settings-system')

  return status
}

/**
 *  Register and initialize navigation context
 */
function initNav() {
  store.commit('app/features/addNavEntry', {
    id: 'connectivity',
    icon: 'mdi-transit-connection-variant',
    title: 'nav.group.conn.title',
    matcher: 'settings/systems',
    shouldBeShown: featureFlags =>
      !featureFlags.includes('dm-hide-admin-menu-entry-system'),
    admin: true,
    items: [
      {
        id: 'connectivity-systems',
        icon: 'mdi-database-cog',
        title: 'nav.item.systems.title',
        route: { name: 'settings-systems' },
      },
    ],
  })
}
