import mask from 'v-mask'
import Vue from 'vue'
import clipboard from 'vue-clipboard2'
import VueSanitize from 'vue-sanitize'

import api from '@api'
import i18n from '@i18n'
import router from '@router'

import model from '@api/model'
import sapi from '@api/service'
import vuetify from '@plugins/vuetify'
import store from '@state/store'
import { getEnvVar } from '@utils/env'
import { initManualAuth } from '@utils/manualAuth'

import App from './App'
import sanitizeConfig from '../html-sanitize.config'

/**
 * First draft to handle features
 * Handling should not happend here, just as a starting point
 */
// eslint-disable-next-line
import systems from '@/features/systems'
// eslint-disable-next-line
import archiveSaaSFeature from '@/features/archivesaas'
// eslint-disable-next-line
import mailfetcherFeature from '@mailfetcher'
// eslint-disable-next-line
import cloudarchiveFeature from '@/features/cloudarchive'

import '@styles/global.scss'

function init() {
  Vue.config.productionTip = false
  clipboard.config.autoSetContainer = true

  Vue.use(mask)
  Vue.use(clipboard)
  Vue.use(VueSanitize, sanitizeConfig)

  Vue.prototype.$api = api
  Vue.prototype.$model = model
  Vue.prototype.$sapi = sapi.init()
}

function mount() {
  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
}

function customer() {
  const customer = store.state.app.login.customer
  const authUrl = `${getEnvVar('VUE_APP_URL')}/auth`
  const url = `${authUrl}/realms/${customer}`

  return sapi.client.get(url).then(() => {
    return customer
  })
}

init()
customer()
  .then(customer => {
    return api.auth.load(customer)
  })
  .finally(() => {
    mount()
    initManualAuth(api.auth, store, router)
  })
