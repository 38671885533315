import { setLabel } from './utils'

export default (model = {}) => {
  return setLabel(
    {
      ...{
        assignee: {
          assignee: '',
        },
        label: {},
        name: '',
        readMatrix: [
          {
            fieldName: '',
            roleAccess: { ext_flowstate_assignee_role_key: true },
          },
        ],
        transitions: [],
        typeId: '',
        typename: '',
        writeMatrix: [
          {
            fieldName: '',
            roleAccess: { ext_flowstate_assignee_role_key: true },
          },
        ],
        hooks: {
          EnterState: {
            operations: [{ type: 'Assignment', parameters: {} }],
          },
        },
      },
      ...model,
    },
    'label'
  )
}
