import gm from '@gm/api'

import action from './action'
import auditLog from './auditLog'
import auth from './auth'
import billing from './billing'
import blob from './blob'
import blobPublic from './blobPublic'
import customservice from './customservice'
import display from './display'
import entity from './entity'
import evalapi from './eval'
import extension from './extension'
import folder from './folder'
import idp from './idp'
import process from './process'
import product from './product'
import productActivation from './productActivation'
import quota from './quota'
import role from './role'
import search from './search'
import serviceaccount from './serviceaccount'
import srvo from './srvo'
import state from './state'
import system from './system'
import tenant from './tenant'
import tenantExport from './tenantExport'
import trait from './trait'
import type from './type'
import user from './user'
import userConfig from './userConfig'

export default {
  gm: gm,
  action,
  auditLog,
  auth,
  billing,
  blob,
  blobPublic,
  customservice,
  display,
  tenantExport,
  entity,
  eval: evalapi,
  extension,
  folder,
  idp,
  process,
  product,
  productActivation,
  role,
  search,
  serviceaccount,
  srvo,
  system,
  tenant,
  trait,
  type,
  user,
  state,
  quota,
  userConfig,
}
