import sapi from '@api/service'
const endpoint = 'customers'

/**
 * Module for trial
 * Provides actions to onboard a customer
 */
export default {
  namespaced: true,

  actions: {
    request({ commit }, data) {
      commit('app/view/loading', true, { root: true })
      return sapi.landlord
        .post(endpoint, data)
        .then(() => {
          const msg = 'Customer created successfully'
          sapi.success(msg)
        })
        .finally(() => {
          commit('app/view/loading', false, { root: true })
        })
    },
  },
}
