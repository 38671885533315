import axios from 'axios'

import api from '@api'
import i18n from '@i18n'
import router from '@router'

import store from '@state/store'
import { getEnvVar } from '@utils/env'

import BlobApi from './blob'
import services from './services'

/**
 * Service API
 * @see https://github.com/axios/axios
 */
const sapi = {
  init() {
    setAxiosDefaults()

    Object.keys(services).forEach(name => {
      sapi[name] = create(services[name])
      sapi[name].blob = new BlobApi(sapi[name])
    })

    sapi.client = axios
    sapi.blob = new BlobApi(axios)
    sapi.baseURL = axios.defaults.baseURL
    sapi.getStatus = getStatus
    sapi.isStatus = isStatus
    sapi.headers = headers
    sapi.message = message
    sapi.success = success
    sapi.warning = warning
    sapi.error = error

    return this
  },
  register(service) {
    setAxiosDefaults()

    sapi[service.name] = create(service)
    sapi[service.name].blob = new BlobApi(service)

    return sapi[service.name]
  },
}
export default sapi

/**
 * set defaults for an axios instance
 */
function setAxiosDefaults() {
  axios.defaults.baseURL = getEnvVar('VUE_APP_URL') + '/krakend'
  axios.defaults.headers.put['Content-Type'] = 'application/json'
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  axios.defaults.headers.patch['Content-Type'] = 'application/json'
}

/**
 * creates an axios instance
 * @param {object} service - service config
 * @returns {object} axios instance
 */
function create(service) {
  const config = {
    baseURL: buildURL(service),
    headers: {
      'X-es-apikey': service.apikey,
      'X-es-system': service.system,
    },
  }
  const srv = axios.create(config)
  srv.config = config
  srv.headers = function () {
    return headers(config.headers)
  }

  srv.interceptors.request.use(
    config => {
      config.headers = headers(config.headers)
      return config
    },
    err => {
      return Promise.reject(err)
    },
    {
      synchronous: true,
    }
  )

  srv.interceptors.response.use(
    res => {
      return res
    },
    err => {
      if (!err.config || !err.config.omitError) {
        sapi.error(err)
      }
      return Promise.reject(err)
    },
    {
      synchronous: true,
    }
  )
  return srv
}

/**
 * the default success handler
 * @returns {string} msg
 */
function success(msg) {
  store.commit('app/alert/success', msg)
}

/**
 * the default warning handler
 * @returns {string} msg
 */
function warning(msg) {
  store.commit('app/alert/warning', msg)
}

/**
 * the default error handler
 * @returns {error} err
 */
function error(err, msg = '') {
  if (!msg) {
    msg = message(getStatus(err), getErrKey(err))
  }
  if (getStatus(err) === 401) {
    api.auth.authenticated = false
    window.location.reload()
  } else if (
    getStatus(err) === 403 &&
    err.config.method === 'get' &&
    err.config.baseURL.endsWith(services.webadapter.url)
  ) {
    if (router.$last?.name === 'error-403') {
      router.go(-1)
    } else {
      router.push({
        name: 'error-403',
        params: { message: err.response?.data?.message || msg },
      })
    }
  } else if (getStatus(err) === 404) {
    router.push({
      name: 'error-404',
    })
  } else {
    store.commit('app/alert/error', msg)
  }
}

/**
 * builds the url for the given service
 * @param {object} service - service config
 * @returns {string} url
 */
function buildURL(service) {
  return service.local
    ? service.url
    : `${axios.defaults.baseURL}/${service.url}`
}

/**
 * checks an error status
 * @param {error} err - the error
 * @param {number} status - the status
 * @returns {boolean} true/false
 */
function isStatus(err, status) {
  return getStatus(err) === status
}

/**
 * gets an error key or undefined if none exists
 * @param {error} err - the error
 * @returns {string|undefined} the error key
 */
function getErrKey(err) {
  if (err.response?.data?.key) {
    return err.response.data.key
  }
}

/**
 * gets an error status or undefined if none exists
 * @param {error} err - the error
 * @returns {number|undefined} the status
 */
function getStatus(err) {
  if (err.response && err.response.status) {
    return err.response.status
  }
}

/**
 * returns a message for a status or a standard
 * message if no specific message is configured
 * @param {number} status - the status
 * @param {string} errKey - the error key (e.g., "name-conflict")
 * @returns {string} the message or default
 */
function message(status, errKey) {
  let key = 'ui.alert.error'
  if (status) {
    key = `api.status.${status}.default`
    if (!i18n.te(key)) {
      key = 'api.status.default'
    }
    if (errKey && i18n.te(`api.status.${status}.${errKey}`)) {
      key = `api.status.${status}.${errKey}`
    }
  }
  return i18n.t(key, {
    status: status,
  })
}

/**
 * gets the context specific headers from the state
 * @param {object} obj - optional headers to extend (gets copied)
 * @returns {object} headers
 */
function headers(obj) {
  let headers = {}
  if (store.state.app.context.token) {
    headers.Authorization = `Bearer ${store.state.app.context.token}`
  }
  if (store.state.app.context.tenant) {
    headers['X-es-tenant'] = store.state.app.context.tenant.id
  } else if (store.state.app.login.customer) {
    headers['X-es-customer'] = store.state.app.login.customer
  }
  headers = obj ? Object.assign({}, headers, obj) : headers
  return headers
}
