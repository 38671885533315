import Keycloak from 'keycloak-js'

import store from '@state/store'
import { getEnvVar } from '@utils/env'

class AuthService {
  constructor(config = {}) {
    this.config = config
    this.ready = false
    this.authenticated = false
    this.keycloak = null
    this.logoutInterval = null
  }

  async load(realm, token, refreshToken, clientId) {
    if (!realm || realm === this.config.realm) {
      return this.authenticated
    }

    this.config.realm = realm

    const initOptions = {
      token,
      refreshToken,
      checkLoginIframe: typeof window.flutter_inappwebview === 'undefined',
    }

    if (clientId) {
      this.config.clientId = clientId
    }

    this.keycloak = new Keycloak(this.config)

    this.keycloak.onReady = authenticated => {
      this.update(authenticated)
      this.ready = true
    }

    this.keycloak.onAuthSuccess = () => {
      this.setupTokenRefresh()
    }

    this.keycloak.onAuthRefreshSuccess = () => {
      this.update(true)
      store.commit('app/context/token')
    }

    this.keycloak.onTokenExpired = () => {
      this.updateToken()
    }

    this.keycloak.onAuthRefreshError = () => {
      this.updateToken()
    }

    try {
      await this.keycloak.init(initOptions)
      return this.keycloak
    } catch (e) {
      if (typeof window.flutter_inappwebview !== 'undefined') {
        window.flutter_inappwebview.callHandler('auth:manualDone', false)
      }
      throw e
    }
  }

  updateToken() {
    this.keycloak.updateToken(60).catch(() => {
      this.keycloak.clearToken()
    })
  }

  setupTokenRefresh() {
    this.logoutInterval = setInterval(() => {
      this.updateToken()
    }, 10000)
  }

  logout(options) {
    if (this.logoutInterval) {
      clearInterval(this.logoutInterval)
    }
    this.keycloak.logout(options)
  }

  update(isAuthenticated = false) {
    this.authenticated = isAuthenticated
    Object.assign(this, this.keycloak)
  }
}

const authUrl = `${getEnvVar('VUE_APP_URL')}/auth`
const config = {
  url: authUrl,
  clientId: 'es-dm',
}

const auth = new AuthService(config)

export default auth
