import i18n from '@i18n'

import sapi from './service'

const endpoint = 'eval'
const compile = 'condition/compile'
const render = 'template/render'

export default {
  async compile(condition, entity = undefined, copy = undefined) {
    const { data } = await sapi.webadapter.post(
      `${endpoint}/${compile}`,
      {
        condition,
        entity,
        copy,
      },
      { omitError: true }
    )
    return data
  },
  async batchCompile(conditions = [], entity = undefined, copy = undefined) {
    const { data } = await sapi.webadapter.post(
      `${endpoint}/${compile}/batch`,
      {
        conditions,
        entity,
        copy,
      }
    )
    return data
  },
  async render(
    template,
    entity = undefined,
    copy = undefined,
    disableError = false
  ) {
    if (template.includes('{{')) {
      try {
        const { data } = await sapi.webadapter.post(
          `${endpoint}/${render}`,
          {
            template,
            entity,
            copy,
          },
          { omitError: true }
        )
        return data
      } catch (e) {
        if (
          sapi.isStatus(e, 400) &&
          e.response?.data?.origin?.key === 'mustache-render' &&
          e.response?.data?.origin?.origin?.message
        ) {
          if (!disableError) {
            sapi.error(
              e,
              i18n.t('search.error.renderTemplate', {
                msg: e.response.data.origin.origin.message,
              })
            )
          }
        } else {
          sapi.error(e)
        }
        return Promise.reject(e)
      }
    } else {
      return template
    }
  },
}
