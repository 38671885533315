import store from '@state/store'

/**
 * Blob API
 * Functions for handling binary data
 */
export default class BlobApi {
  /**
   * creates a blob api
   * @param {object} client - the axios client
   */
  constructor(client) {
    this.client = client
  }

  /**
   * downloads the file to a local url for usage as src
   * @param {string} url - the url or endpoint
   * @param {object} file - the file object
   * @param {string} file.id - the file id
   * @returns {Promise} the request promise
   */
  async src(url, file) {
    const { data } = await this.client.get(`${url}/${file.id}`, {
      responseType: 'blob',
    })
    return URL.createObjectURL(data)
  }

  /**
   * fetches the file metadata
   * @param {string} url - the url or endpoint
   * @param {object} file - the file object
   * @returns {Promise<{contentLength: number}>} the result promise
   */
  async info(url, file) {
    const { data } = await this.client.get(`${url}/${file.id}/info`)

    return data
  }

  /**
   * downloads a preview for the file
   * @param {string} url - the url or endpoint
   * @param {object} file - the file object
   * @param {object} opts - opts to modify request behaviour
   * @returns {Promise} the request promise
   */
  async preview(url, file, opts = {}) {
    return this.client.get(`${url}/${file.id}/preview`, {
      responseType: 'blob',
      ...opts,
    })
  }

  /**
   * reads the file as base64
   * @param {string} url - the url or endpoint
   * @param {object} file - the file object
   * @param {string} file.id - the file id
   * @returns {Promise} the result promise
   */
  data(url, file) {
    return new Promise((resolve, reject) => {
      this.client
        .get(`${url}/${file.id}`, {
          responseType: 'blob',
        })
        .then(res => {
          const reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.readAsDataURL(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  /**
   * reads the file as text
   * @param {string} url - the url or endpoint
   * @param {object} file - the file object
   * @param {string} file.id - the file id
   * @returns {Promise} the result promise
   */
  text(url, file) {
    return new Promise((resolve, reject) => {
      this.client
        .get(`${url}/${file.id}`, {
          responseType: 'blob',
        })
        .then(res => {
          const reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.readAsText(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  /**
   * downloads the file in the browser
   * @param {string} url - the url or endpoint
   * @param {object} file - the file object
   * @param {string} file.id - the file id
   * @returns {Promise} the request promise
   */
  async download(url, file) {
    const { data } = await this.client.get(`${url}/${file.id}`, {
      responseType: 'blob',
    })
    const blob = new Blob([data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = file.name || 'unnamed'
    link.click()
    URL.revokeObjectURL(link.href)
  }

  /**
   * uploads a file
   * @param {string} url - the url or endpoint
   * @param {object} file - the file object
   * @param {string} file.id - the file id
   * @param {File} file.file - the file object
   * @param {string=} progressMutation - the mutation for progress subscription
   * @param {boolean=} omitError - omit error
   * @returns {Promise} the request promise
   */
  async upload(url, file, progressMutation, omitError = false) {
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await this.client.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: uploadProgress(file, progressMutation),
      omitError,
    })
    return data
  }
}

/**
 * calculates and commits the upload progress
 * @param {object} file - the file object
 * @param {string} file.id - the file id
 * @param {File} file.file - the file object
 * @param {string=} progressState - the mutation for progress subscription
 */
function uploadProgress(file, progressMutation) {
  return function (progressEvent) {
    const percent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    if (progressMutation) {
      store.commit(progressMutation, { percent: percent, file: file })
    }
  }
}
