import sapi from './service'

export default {
  async update(item) {
    await sapi.landlord.put(
      `customers/${item.customer}/tenants/${item.id}`,
      item
    )
    return item
  },
}
