import { cloneDeep as clone } from 'lodash'

import sapi from '@api/service'

const endpoint = 'user/config/modules'
const moduleId = 'webscan'

/**
 * Initial user specific webscan application settings
 */
const initialSettings = {
  scanner: '',
  profile: '',
}

export default {
  namespaced: true,

  state: () => ({
    userSettings: clone(initialSettings),
  }),

  getters: {
    userSettings: state => {
      return state.userSettings
    },
  },

  mutations: {
    userSettings(state, config) {
      state.userSettings = config
    },
  },

  actions: {
    load({ state, commit }) {
      return sapi.landlord
        .get(`${endpoint}/${moduleId}`, {
          omitError: true,
        })
        .then(res => {
          const data = res.data || {}
          commit('userSettings', data)
          return data
        })
        .catch(err => {
          if (sapi.isStatus(err, 404)) {
            commit('userSettings', initialSettings)
            return state.userSettings
          } else {
            sapi.error(err)
          }
        })
    },
    create({ commit }, { config, banner = true }) {
      config.id = moduleId
      return sapi.landlord.post(endpoint, config).then(res => {
        if (banner) {
          sapi.success(this._vm.$t('gm.webscan.user.alert.created'))
        }
        commit('userSettings', res.data)
        return res.data
      })
    },
    update({ commit }, { config, banner = true }) {
      sapi.landlord.put(`${endpoint}/${moduleId}`, config).then(res => {
        if (banner) {
          sapi.success(this._vm.$t('gm.webscan.user.alert.updated'))
        }
        commit('userSettings', res.data)
      })
    },
  },
}
