import { includeClaritySdk, removeConsent } from '@utils/telemetry'

export const ConfirmationState = {
  UNCONFIRMED: 'unconfirmed',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
}

/**
 * Module for telemetry options
 */
export default {
  namespaced: true,

  state: () => ({
    status: ConfirmationState.UNCONFIRMED,
  }),

  mutations: {
    status(state, consent) {
      state.status = consent
      localStorage.setItem('app/telemetry-consent', consent)
    },
  },

  actions: {
    init({ commit }) {
      const val =
        localStorage.getItem('app/telemetry-consent') ??
        ConfirmationState.UNCONFIRMED

      commit('status', val)

      if (val === ConfirmationState.ACCEPTED) {
        includeClaritySdk()
      }
    },
    consent({ commit }) {
      commit('status', ConfirmationState.ACCEPTED)
      includeClaritySdk()
    },
    removeConsent({ commit }) {
      commit('status', ConfirmationState.REJECTED)

      removeConsent()
    },
    clear({ commit }) {
      commit('status', ConfirmationState.UNCONFIRMED)
      localStorage.removeItem('app/telemetry-consent')
    },
  },

  getters: {
    requireConsent: state => state.status === ConfirmationState.UNCONFIRMED,
  },
}
