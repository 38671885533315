import VueSanitize from 'vue-sanitize'

export default {
  allowedTags: VueSanitize.defaults.allowedTags.concat([
    'img',
    'blockquote',
    'br',
  ]),
  allowedAttributes: Object.assign(VueSanitize.defaults.allowedAttributes, {
    '*': [
      'href',
      'align',
      'alt',
      'center',
      'bgcolor',
      'style',
      'class',
      'data-checked',
    ],
  }),
  allowedSchemesByTag: Object.assign(VueSanitize.defaults.allowedSchemesByTag, {
    img: ['data'],
  }),
}
