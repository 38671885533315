import TimeoutPage from '@pages/Error408Page'
import LoadingPage from '@pages/LoadingPage'

/**
 * @function load
 * async component loader
 */
export default function load(comp) {
  const AsyncHandler = () => ({
    component: comp,
    loading: LoadingPage,
    error: TimeoutPage,
    delay: 200,
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}
