export default {
  namespaced: true,

  state: () => ({
    activeActivation: undefined,
  }),

  mutations: {
    setActive(state, id) {
      state.activeActivation = id
    },
    clearActivation(state) {
      state.activeActivation = undefined
    },
  },

  actions: {
    async start({ commit }, id) {
      commit('setActive', id)
    },
    async finish({ commit }) {
      commit('clearActivation')
    },
  },
}
