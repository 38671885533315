import sapi from '@api/service'

const endpoint = 'login'

/**
 * Module for login process
 * Holds information of a user during login process
 */
export default {
  namespaced: true,

  state: () => ({
    username: '',
    customer: '',
    tenant: '',
    hint: '',
    idp: 'dm-azure',
  }),

  mutations: {
    username(state, username) {
      state.username = username
      localStorage.setItem('login/username', username)
    },
    customer(state, customer) {
      state.customer = customer
      localStorage.setItem('login/customer', customer)
    },
    tenant(state, tenant) {
      state.tenant = tenant
      localStorage.setItem('login/tenant', tenant)
    },
    hint(state, user) {
      state.hint = user
    },
    idp(state, idp) {
      state.idp = idp
    },
  },

  actions: {
    init({ commit }) {
      const cust = localStorage.getItem('login/customer')
      if (cust) {
        commit('customer', cust)
      }
      const username = localStorage.getItem('login/username')
      if (username) {
        commit('username', username)
      }
      const tenant = localStorage.getItem('login/tenant')
      if (tenant) {
        commit('tenant', tenant)
      }
    },
    idps(_, { customer, emptyTenant }) {
      const headers = { 'X-es-customer': customer }
      if (emptyTenant) {
        headers['X-es-tenant'] = undefined
      }
      return sapi.landlord
        .get(`${endpoint}/identity-providers`, {
          headers,
        })
        .then(res => {
          return res.data || []
        })
    },
    customer({ commit, state }, { tenant, username }) {
      let tenantPath = ''
      if (state.tenant && state.tenant !== '') {
        tenantPath = `/${state.tenant}`
      } else if (tenant) {
        tenantPath = `/${tenant}`
      }
      return sapi.landlord
        .get(`${endpoint}/customer${tenantPath}`, {
          headers: { 'X-es-username': username },
          omitError: true,
        })
        .then(res => {
          commit('customer', res.data.id ? res.data.id : '')
          commit('username', res.data.username ? username : '')
          commit('idp', res.data.idp ? res.data.idp : '')
          return res.data
        })
        .catch(err => {
          commit('customer', '')
          if (sapi.isStatus(err, 404)) {
            return Promise.resolve('')
          } else {
            sapi.error(err)
          }
        })
    },
  },
}
