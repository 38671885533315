import { getEnvVar } from '@utils/env'

/**
 * @returns {Promise<boolean>} a promise resolving once the sdk was loaded
 */
export function includeClaritySdk() {
  if (window.clarity) {
    return Promise.resolve(false)
  }

  const projectId = getEnvVar('CLARITY_PROJECT_ID')
  if (!projectId) {
    return Promise.resolve(false)
  }

  // snippet provided by clarity
  return new Promise((resolve, reject) => {
    ;(function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          ;(c[a].q = c[a].q || []).push(arguments)
        }
      t = l.createElement(r)
      t.async = 1
      t.src = 'https://www.clarity.ms/tag/' + i
      t.addEventListener('load', resolve)
      t.addEventListener('error', reject)
      y = l.getElementsByTagName(r)[0]
      y.parentNode.insertBefore(t, y)
    })(window, document, 'clarity', 'script', projectId)
  })
    .then(() => consent())
    .then(() => true)
}

export function consent() {
  window.clarity?.('consent')
}

/**
 * Clears cookies by clarity and reloads page to remove loaded script from memory
 */
export function removeConsent() {
  if (window.clarity) {
    window.clarity('consent', false)
  }
}
